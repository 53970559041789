import keyBy from 'lodash/keyBy';
import { createSlice, original } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axiosNew';
import { setMessagesByChatBySession } from '../../services/WhatsAppChatService';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  contacts: { byId: {}, allIds: [] }, // Chats
  newContacts: { byId: {}, allIds: [] },
  conversations: { byId: {}, allIds: [] },
  activeConversationId: null,
  participants: [],
  recipients: [],
};

const slice = createSlice({
  name: 'whatsappChat',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CONTACT SSUCCESS
    getContactsSuccess(state, action) {
      const contacts = action.payload;

      state.contacts.byId = keyBy(contacts, 'id');
      state.contacts.allIds = Object.keys(state.contacts.byId);
    },

    // GET CONVERSATIONS
    getConversationsSuccess(state, action) {
      const conversations = action.payload;

      state.conversations.byId = keyBy(conversations, 'id');
      state.conversations.allIds = Object.keys(state.conversations.byId);
    },

    setConversationOrderSuccess(state, action) {
      const allIds = action.payload;

      state.conversations.allIds = allIds;
    },

    // GET CONVERSATION
    getConversationSuccess(state, action) {
      const conversation = action.payload;

      if (conversation) {
        state.conversations.byId[conversation.id] = conversation;
        state.activeConversationId = conversation.id;
        if (!state.conversations.allIds.includes(conversation.id)) {
          state.conversations.allIds.push(conversation.id);
        }
      } else {
        state.activeConversationId = null;
      }
    },

    // GET CONVERSATION
    getConversation(state, action) {
      const conversationKey = action.payload;

      if (conversationKey) {
        const conversation = state.conversations.byId[conversationKey];
        state.conversations.byId[conversationKey] = conversation;
        state.activeConversationId = conversationKey;
        if (!state.conversations.allIds.includes(conversationKey)) {
          state.conversations.allIds.push(conversationKey);
        }
      } else {
        state.activeConversationId = null;
      }
    },

    getNewContactsSuccess(state, action) {
      const newContacts = action.payload;

      state.newContacts.byId = keyBy(newContacts, 'id');
      state.newContacts.allIds = Object.keys(state.newContacts.byId);
    },

    // ON SEND MESSAGE
    sendMessage(state, action) {
      const { conversation, conversations } = action.payload;
      const { conversationId, messageId, message, contentType, attachments, createdAt, senderId } = conversation;

      const newMessage = {
        id: messageId,
        body: message,
        contentType,
        attachments,
        createdAt,
        senderId,
      };

      // si no existe la conversacion que la cree
      if (!state.conversations.allIds.includes(conversation.conversationId)) {
        console.log('AAAA');

        console.log('conversation', conversation);
        const newConversation = {
          ...conversation,
          to: `506${conversation.conversationId}@c.us`,
          archived: false,
          id: conversation.conversationId,
          messages: [newMessage],
          participants: [],
          type: 'ONE_TO_ONE',
          unreadCount: 0, //  el.unreadCount
          // to: newMessage[0].id.remote,
          // from: newMessage[0]?.fromMe ? newMessage[0].id.remote : '',
        };
        console.log('newConversation', newConversation);
        console.log('state.conversations.allIds', state.conversations.allIds);

        // state.conversations.byId[conversation.conversationId] = newConversation;
        state.conversations.byId = { ...conversations.byId, [conversation.conversationId]: newConversation };
        console.log('xddddd', { ...conversations.byId, [conversation.conversationId]: newConversation });
        state.conversations.allIds = [...conversations.allIds, conversation.conversationId];
        console.log('state.conversations.allIds', state.conversations.allIds);
        console.log('conversations.byId', state.conversations.byId);
        // state.activeConversationId = conversation.conversationId;
      } else {
        console.log('BBB');
        state.conversations.byId[conversationId].messages.push(newMessage);
        console.log('CC');
      }
    },

    sendMessageInExistenConversation(state, action) {
      const { conversationId, newMessage } = action.payload;
      state.conversations.byId[conversationId].messages.push(newMessage);
    },

    markConversationAsReadSuccess(state, action) {
      const { conversationId } = action.payload;
      const conversation = state.conversations.byId[conversationId];
      if (conversation) {
        conversation.unreadCount = 0;
      }
    },

    // GET PARTICIPANTS
    getParticipantsSuccess(state, action) {
      const participants = action.payload;
      state.participants = participants;
    },

    // RESET ACTIVE CONVERSATION
    resetActiveConversation(state) {
      state.activeConversationId = null;
    },

    addRecipients(state, action) {
      const recipients = action.payload;
      state.recipients = recipients;
    },

    // SET CONVERSATION
    setConversationSuccess(state, action) {
      const conversation = action.payload;

      if (conversation) {
        state.conversations.byId[conversation.id] = conversation;
        if (!state.conversations.allIds.includes(conversation.id)) {
          state.conversations.allIds.push(conversation.id);
        }
      }
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { addRecipients, resetActiveConversation } = slice.actions;

// ----------------------------------------------------------------------

export function getContacts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/chat/contacts');
      // console.log('getContacts', response);
      dispatch(slice.actions.getContactsSuccess(response.data.contacts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getConversations() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/chat/conversations');
      // console.log('getConversations', response);
      dispatch(slice.actions.getConversationsSuccess(response.data.conversations));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

// export function getConversation(conversationKey) {
//   return async (dispatch) => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.get('/api/chat/conversation', {
//         params: { conversationKey },
//       });
//       dispatch(slice.actions.getConversationSuccess(response.data.conversation));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

export function getConversation(conversationKey) {
  return (dispatch) => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.getConversation(conversationKey));
  };
}

// ----------------------------------------------------------------------

export function markConversationAsRead(conversationId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // await axios.get('/api/chat/conversation/mark-as-seen', {
      //   params: { conversationId },
      // });
      dispatch(slice.actions.markConversationAsReadSuccess({ conversationId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

// export function getParticipants(conversationKey) {
//   return async (dispatch) => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.get('/api/chat/participants', {
//         params: { conversationKey },
//       });
//       dispatch(slice.actions.getParticipantsSuccess(response.data.participants));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

export function getParticipants(participants) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    dispatch(slice.actions.getParticipantsSuccess(participants));
  };
}

export function setContacts(contacts) {
  return async (dispatch) => {
    dispatch(slice.actions.getContactsSuccess(contacts));
  };
}

export function setNewContacts(contacts) {
  return async (dispatch) => {
    dispatch(slice.actions.getNewContactsSuccess(contacts));
  };
}

export function setConversations(conversations) {
  return async (dispatch) => {
    dispatch(slice.actions.getConversationsSuccess(conversations));
  };
}

export function setConversationOrder(allIds) {
  return async (dispatch) => {
    dispatch(slice.actions.setConversationOrderSuccess(allIds));
  };
}

export function setConversation(conversation) {
  return async (dispatch) => {
    dispatch(slice.actions.setConversationSuccess(conversation));
  };
}
export function sendMessage({
  conversation,
  conversationObject,
  conversations,
  currentBranch,
  chatId,
  currentSession,
}) {
  return async (dispatch) => {
    console.log('conversation redux', conversation);
    const { conversationId, messageId, message, contentType, attachments, senderId } = conversationObject;

    const originalDate = new Date();
    originalDate.setMilliseconds(0);
    const createdAt = originalDate.toISOString();

    const newMessage = {
      id: messageId,
      body: message,
      contentType,
      attachments,
      createdAt,
      senderId,
    };

    // si no existe la conversacion que la cree
    if (!conversations.allIds.includes(conversationId)) {
      console.log('AAAA');

      // console.log('conversation', conversation);
      // const newConversation = {
      //   ...conversation,
      //   to: `506${conversation.conversationId}@c.us`,
      //   archived: false,
      //   id: conversation.conversationId,
      //   messages: [newMessage],
      //   participants: [],
      //   type: 'ONE_TO_ONE',
      //   unreadCount: 0, //  el.unreadCount
      //   // to: newMessage[0].id.remote,
      //   // from: newMessage[0]?.fromMe ? newMessage[0].id.remote : '',
      // };
      // console.log('newConversation', newConversation);
      // console.log('state.conversations.allIds', state.conversations.allIds);

      // // state.conversations.byId[conversation.conversationId] = newConversation;
      // state.conversations.byId = { ...conversations.byId, [conversation.conversationId]: newConversation };
      // console.log('xddddd', { ...conversations.byId, [conversation.conversationId]: newConversation });
      // state.conversations.allIds = [...conversations.allIds, conversation.conversationId];
      // console.log('state.conversations.allIds', state.conversations.allIds);
      // console.log('conversations.byId', state.conversations.byId);
      // // state.activeConversationId = conversation.conversationId;
      // dispatch(slice.actions.sendMessage({ conversation, conversations }));
    } else {
      console.log('BBB');

      // await setMessagesByChatBySession(
      //   currentBranch.id,
      //   {
      //     conversation: {
      //       ...conversation,
      //       messages: [...conversation.messages, newMessage],
      //     },
      //   },
      //   chatId.replace('@', ''),
      //   currentSession.phoneNumber
      // );

      dispatch(slice.actions.sendMessageInExistenConversation({ conversationId, newMessage }));
      console.log('CC');
    }
  };
}
