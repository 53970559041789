export const switchCurrency = (currency) => {
  let symbol = '$';

  switch (currency) {
    case 'EUR':
      symbol = '€';
      break;
    case 'CRC':
      symbol = '₡';
      break;
    case 'MXN':
      symbol = 'MXN$';
      break;
    case 'GTQ':
      symbol = 'Q';
      break;
    default:
      break;
  }

  // console.log('currency', currency);
  // console.log('symbol', symbol);
  return symbol;
};

const getCompanyToRedux = (values) => {
  // console.log('values', values);
  const companyToRedux = {
    id: values.id,
    name: values.companyName,
    comercialName: values.comercialName,
    photoURLCover: values.photoURLCover,
    photoURL: values.photoURL,
    currency: values.currency || 'USD',
    currencySymbol: switchCurrency(values.currency),
    subscription: values?.subscription,
    rootUser: values.rootUser,
    familyList: values.familyList || [],
    country: values.country || 'cr',
    idNumber: values.idNumber || '',
    email: values.email || '',
    addressLocation: values.addressLocation || null,
    direction: values.direction || '',
    accountNames: values.accountNames || '',
  };

  // console.log('companyToRedux', companyToRedux);
  return companyToRedux;
};

export default getCompanyToRedux;
