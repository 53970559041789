import firebase from 'firebase/app';
import 'firebase/database';
// form
import 'firebase/auth';

const sessionsBaseUrl = 'sessions_company';
const messagesBaseUrl = 'messages_chat_company';
const phonesBaseUrl = 'phones_sessions_company';
const contactsBaseUrl = 'contacts_sessions_company';
const templatesBaseUrl = 'templates_session';

export async function getSessionsByCompanyId(companyId) {
  return firebase
    .database()
    .ref(`/${sessionsBaseUrl}/${companyId}`)
    .get()
    .then((snapshot) => (snapshot.exists() ? snapshot.val() : []))
    .catch((error) => {
      console.error(error);
    });
}

export async function createSession(companyId, data) {
  await firebase
    .database()
    .ref(`/${sessionsBaseUrl}/${companyId}`)
    .set(data)
    .catch((error) => {
      console.error(error);
    });
}

export async function getMessageByChatBySession(companyId, chatId, sessionId) {
  return firebase
    .database()
    .ref(`/${messagesBaseUrl}/${companyId}/${chatId}/${sessionId}`)
    .get()
    .then((snapshot) => (snapshot.exists() ? snapshot.val() : []))
    .catch((error) => {
      console.error(error);
    });
}

export async function getNameByChatBySession(companyId, chatId, sessionId) {
  return firebase
    .database()
    .ref(`/${messagesBaseUrl}/${companyId}/${chatId}/${sessionId}/userName`)
    .get()
    .then((snapshot) => (snapshot.exists() ? snapshot.val() : []))
    .catch((error) => {
      console.error(error);
    });
}

export const listenForChangesInConversation = async (companyId, sessionId, callback) => {
  const myTable = firebase.database().ref(`/${messagesBaseUrl}/${companyId}/${sessionId}`);
  myTable.on('child_changed', async (snapshot) => {
    const data = snapshot.val();
    console.log('data Realtime child_changed', data);
    callback(data);
  });
};

export const unsubscribeConversation = async (companyId, sessionId, callback) => {
  const myTable = firebase.database().ref(`/${messagesBaseUrl}/${companyId}/${sessionId}`);

  // Para desubscribirte del evento, utiliza la referencia al callback
  myTable.off('child_changed', callback);
};

/* Ya se guarda en firebase cuando se toca un chat pero se guardan los msj que ya traían, 
lo ideal sería que junte los que están en firebase con los nuevos que trae con el allMessages del backend,
 no se tienen que repetir e ir en orden.
*/
export async function setMessagesByChatBySession(companyId, data, chatId, sessionId) {
  console.log(`/${messagesBaseUrl}/${companyId}/${sessionId}/${chatId.replace('@', '').replace('.', '')}`);
  console.log('data', data);
  await firebase
    .database()
    .ref(`/${messagesBaseUrl}/${companyId}/${sessionId}/${chatId.replace('@', '').replace('.', '')}`)
    .set(data)
    .catch((error) => {
      console.error(error);
    });
}

export async function getConversationsBySession(companyId, sessionId) {
  return (
    firebase
      .database()
      .ref(`/${messagesBaseUrl}/${companyId}/${sessionId}`)
      .get()
      // .then((snapshot) => (snapshot.exists() ? snapshot.val() : []))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const val = snapshot.val();
          Object.keys(val).forEach((id) => {
            val[id].sessionId = sessionId;
          });
          // console.log('val xd', val);
          return val;
        }
        return [];
      })
      .catch((error) => {
        console.error(error);
      })
  );
}

export async function setConversationsBySession(companyId, sessionId, data) {
  data.forEach(async (conversation) => {
    await firebase
      .database()
      .ref(`/${messagesBaseUrl}/${companyId}/${sessionId}/${conversation.to.replace('@', '').replace('.', '')}`)
      .set({ conversation })
      .catch((error) => {
        console.error(error);
      });
  });
}

export async function setFirebaseConversationsBySession(companyId, sessionId, data) {
  data.forEach(async (conversation) => {
    console.log('conversation', conversation);
    console.log('conversation to', conversation?.to);
    await firebase
      .database()
      .collection(messagesBaseUrl)
      .doc(companyId)
      .collection(sessionId)
      .doc(conversation.conversation.to.replace('@', '').replace('.', ''))
      .set({ conversation })
      .catch((error) => {
        console.error(error);
      });
  });
}

export async function setUnreadCountTo0(companyId, sessionId, to) {
  await firebase
    .database()
    .ref(`/${messagesBaseUrl}/${companyId}/${sessionId}/${to}/unreadCount`)
    .set(0)
    .catch((error) => {
      console.error(error);
    });
}

export async function getAllSessions() {
  return firebase
    .database()
    .ref(`/${phonesBaseUrl}`)
    .get()
    .then((snapshot) => (snapshot.exists() ? snapshot.val() : []))
    .catch((error) => {
      console.error(error);
    });
}

export async function setSessionByPhone(phoneNumber, companyId) {
  await firebase
    .database()
    .ref(`/${phonesBaseUrl}/${phoneNumber}`)
    .set({ companyId })
    .catch((error) => {
      console.error(error);
    });
}

export async function getSessionByPhone(phoneNumber) {
  return firebase
    .database()
    .ref(`/${phonesBaseUrl}/${phoneNumber}`)
    .get()
    .then((snapshot) => (snapshot.exists() ? snapshot.val() : []))
    .catch((error) => {
      console.error(error);
    });
}

export async function getPhonesByCompany(companyId) {
  return firebase
    .firestore()
    .collection(phonesBaseUrl)
    .doc(companyId)
    .get()
    .then((snap) => {
      const data = snap.data();
      console.log('snap', snap);
      console.log('data', data);
    })
    .catch((error) => {
      console.error(error);
    });
}

const deleteSessionByPhone = async (companyId, phoneNumber, sessionId) => {
  const docRef = firebase.firestore().collection(phonesBaseUrl).doc(companyId).collection(phoneNumber).doc(sessionId);

  await docRef.delete();
};

export async function getContactsBySessionByCompany(companyId, sessionId) {
  return firebase
    .database()
    .ref(`/${contactsBaseUrl}/${companyId}/${sessionId}`)
    .get()
    .then((snapshot) => (snapshot.exists() ? snapshot.val() : {}))
    .catch((error) => {
      console.error(error);
    });
}

// export async function getContactsBySessionByCompany(companyId, sessionId, id) {
//   return firebase
//     .database()
//     .ref(`/${messagesBaseUrl}/${companyId}/${sessionId}/${id}/userName`)
//     .get()
//     .then((snapshot) => (snapshot.exists() ? snapshot.val() : {}))
//     .catch((error) => {
//       console.error(error);
//     });
// }

export async function setContactBySessionByCompany(companyId, sessionId, contactId, data) {
  await firebase
    .database()
    .ref(`/${contactsBaseUrl}/${companyId}/${sessionId}/${contactId}`)
    .set(data)
    .catch((error) => {
      console.error(error);
    });
}

export async function setContactsBySessionByCompany(companyId, sessionId, data) {
  await firebase
    .database()
    .ref(`/${contactsBaseUrl}/${companyId}/${sessionId}`)
    .set(data)
    .catch((error) => {
      console.error(error);
    });
}

export const listenForChangesInContacts = async (companyId, sessionId, callback) => {
  const myTable = firebase.database().ref(`/${contactsBaseUrl}/${companyId}/${sessionId}`);
  myTable.on('child_changed', async (snapshot) => {
    // const data = await getInvoiceReportApiHaciendaById(id, branchID);
    const data = snapshot.val();
    console.log('data Realtime child_changed', data);
    callback(data);
  });
};

export async function setTemplateBySession(phoneNumber, data) {
  await firebase
    .database()
    .ref(`/${templatesBaseUrl}/${phoneNumber}`)
    .set(data)
    .catch((error) => {
      console.error(error);
    });
}

export async function getTemplateBySession(phoneNumber) {
  return firebase
    .database()
    .ref(`/${templatesBaseUrl}/${phoneNumber}`)
    .get()
    .then((snapshot) => (snapshot.exists() ? snapshot.val() : []))
    .catch((error) => {
      console.error(error);
    });
}

export async function getAllSessionChatsByCompany(companyId) {
  return (
    firebase
      .database()
      .ref(`/${messagesBaseUrl}/${companyId}`)
      .get()
      // .then((snapshot) => (snapshot.exists() ? snapshot.val() : {}))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const val = snapshot.val();
          console.log('val xd', val);
          // add sessionId
          Object.keys(val).forEach((sessionId) => {
            Object.keys(val[sessionId]).forEach((id) => {
              console.log('id', id);
              val[sessionId][id].sessionId = sessionId;
            });
          });
          // console.log('val xd', val);

          return val;
        }
        return [];
      })
      .catch((error) => {
        console.error(error);
      })
  );
}
