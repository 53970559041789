import firebase from 'firebase/app';
import { editUserPointsRating, getUserPointsRating } from './UserService';

const RATINGS_BASE_URL = 'ratings';
const USER_LOG_BASE_URL = 'rating_logs_user';

export async function getAllRatings() {
  return firebase
    .database()
    .ref()
    .child(`${RATINGS_BASE_URL}`)
    .get()
    .then((snapshot) => (snapshot.exists() ? snapshot.val() : []))
    .catch((error) => {
      console.error(error);
    });
}

export async function getRatingById(moduleType) {
  return firebase
    .database()
    .ref()
    .child(`${RATINGS_BASE_URL}/${moduleType}`)
    .get()
    .then((snapshot) => (snapshot.exists() ? snapshot.val() : {}))
    .catch((error) => {
      console.error(error);
    });
}

export const createNewRating = async (rating) => {
  try {
    const rootRef = firebase.database().ref('/');
    const ratingRef = rootRef.child(`${RATINGS_BASE_URL}/${rating.moduleType}`);
    const newRating = await ratingRef.set(rating);

    // const updates = {};
    // updates[`/${RATINGS_BASE_URL}/${rating.moduleType}/${newRating.key}/id`] = newRating.key;
    // await firebase.database().ref().update(updates);

    return { id: newRating.key };
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const updateRating = async (rating) => {
  const updates = {};
  updates[`${RATINGS_BASE_URL}/${rating.moduleType}`] = rating;

  await firebase.database().ref().update(updates);
};

export const deleteRating = async (moduleType) => {
  const ratingRef = firebase.database().ref(`/${RATINGS_BASE_URL}/${moduleType}`);
  await ratingRef.remove();
};

export async function getUserLogs(uid) {
  return firebase
    .database()
    .ref()
    .child(`${USER_LOG_BASE_URL}/${uid}`)
    .get()
    .then((snapshot) => (snapshot.exists() ? snapshot.val() : []))
    .catch((error) => {
      console.error(error);
    });
}

export const createUserLog = async (uid, newLog) => {
  const rootRef = firebase.database().ref('/');
  const ratingRef = rootRef.child(`${USER_LOG_BASE_URL}/${uid}`);
  await ratingRef.push(newLog);
};

export const addPointsToUser = async (moduleType, action, user, documentId = '') => {
  const rating = await getRatingById(moduleType);
  // console.log('rating', rating);
  // console.log('rating[action]', rating[action]);
  if (rating && rating[action]) {
    const newPoints = Number(rating[action]);
    // console.log('newPoints', newPoints);
    // console.log('user', user);

    const pointsRating = await getUserPointsRating(user.uid);
    // console.log('pointsRating', pointsRating);

    const newPointsRating = pointsRating + newPoints;
    // console.log('newPointsRating', newPointsRating);

    await editUserPointsRating(user.uid, newPointsRating);

    const currentDate = new Date().toISOString();
    const newLog = {
      action,
      currentDate,
      moduleType,
      newPoints,
      documentId,
    };
    await createUserLog(user.uid, newLog);
  }
};
