import { createSlice } from '@reduxjs/toolkit';
import omit from 'lodash/omit';
// utils
// import axios from '../../utils/axios';
//
// import { dispatch } from '../store';
import { keyBy } from 'lodash';
import {
  changeColumnOrder,
  changeColumns,
  createBoard,
  createCard,
  deleteCard,
  getCurrentBoard,
} from '../../services/WaitingRoomService';
import { addPointsToUser } from '../../services/RatingsService';
import { getChatsToUp } from '../../views/Chat/ApiWhatsapp/UtilsMeta';

// ----------------------------------------------------------------------

// function objFromArray(array, key = 'id') {
//   console.log('array', array);
//   if (Object.keys(array).length === 0) {
//     return [];
//   }
//   return array?.reduce((accumulator, current) => {
//     accumulator[current[key]] = current;
//     return accumulator;
//   }, {});
// }

const initialState = {
  isLoading: false,
  error: null,
  usersList: [],
  clientsList: [],
  currentCard: {},
  currentBoard: {
    cards: {},
    columns: {
      cero: {
        cardIds: [],
        id: 'cero',
        name: 'Sin atender',
      },
      one: {
        cardIds: [],
        id: 'one',
        name: 'Conversando',
      },
      two: {
        cardIds: [],
        id: 'two',
        name: 'En pausa',
      },
      three: {
        cardIds: [],
        id: 'three',
        name: 'Atrasado',
      },
      four: {
        cardIds: [],
        id: 'four',
        name: 'Finalizado',
      },
    },
    currentSession: {},
    columnOrder: ['cero', 'one', 'two', 'three', 'four'],
  },
};

const slice = createSlice({
  name: 'waitingRoom',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET BOARD
    setBoard(state, action) {
      const { currentBoard, currentSession } = action.payload;
      state.currentBoard = currentBoard;
      state.currentSession = currentSession;

      // state.isLoading = false;
      // const board = action.payload;
      // const cards = objFromArray(board?.cards);
      // const columns = objFromArray(board?.columns);
      // const columnOrder = board?.columnOrder;
      // state.currentBoard = {
      //   cards,
      //   columns,
      //   columnOrder,
      // };
    },

    // CREATE NEW COLUMN
    createColumnSuccess(state, action) {
      const newColumn = action.payload;
      state.isLoading = false;
      state.currentBoard.columns = {
        ...state.currentBoard.columns,
        [newColumn.id]: newColumn,
      };
      state.currentBoard.columnOrder.push(newColumn.id);
    },

    persistCard(state, action) {
      const { columns, card, finish } = action.payload;
      state.currentBoard.columns = columns;
      state.currentBoard.cards[card.id].columnId = finish.id;
    },

    persistColumn(state, action) {
      state.currentBoard.columnOrder = action.payload;
    },

    addTask(state, action) {
      const { card, columnId, currentBoard } = action.payload;

      // console.log('card', card);

      if (!currentBoard.cards) {
        state.currentBoard = {
          ...currentBoard,
          cards: {},
        };
      }
      if (!currentBoard.columns[columnId].cardIds) {
        state.currentBoard = {
          ...state.currentBoard,
          columns: {
            ...state.currentBoard.columns,
            [columnId]: {
              ...state.currentBoard.columns[columnId],
              cardIds: [],
            },
          },
        };
      }

      state.currentBoard.cards[card.id] = card;
      state.currentBoard.columns[columnId].cardIds.push(card.id);
    },

    deleteTask(state, action) {
      const { cardId, columnId } = action.payload;

      state.currentBoard.columns[columnId].cardIds = state.currentBoard.columns[columnId].cardIds.filter(
        (id) => id !== cardId
      );
      state.currentBoard.cards = omit(state.currentBoard.cards, [cardId]);
    },

    // UPDATE COLUMN
    // updateColumnSuccess(state, action) {
    //   const column = action.payload;

    //   state.isLoading = false;
    //   state.currentBoard.columns[column.id] = column;
    // },

    // DELETE COLUMN
    // deleteColumnSuccess(state, action) {
    //   const { columnId } = action.payload;
    //   const deletedColumn = state.currentBoard.columns[columnId];

    //   state.isLoading = false;
    //   state.currentBoard.columns = omit(state.currentBoard.columns, [columnId]);
    //   state.currentBoard.cards = omit(state.currentBoard.cards, [...deletedColumn.cardIds]);
    //   state.currentBoard.columnOrder = state.currentBoard.columnOrder.filter((c) => c !== columnId);
    // },

    // clear board
    clear(state, action) {
      state.currentBoard = action.payload;
    },

    // SET CURRENT BOARD
    setCurrentBoard(state, action) {
      state.currentBoard = action.payload;
    },

    // SET CURRENT CARD
    setCurrentCard(state, action) {
      state.currentCard = action.payload;
    },

    // SET USERS LIST
    setUsersList(state, action) {
      state.usersList = action.payload;
    },

    // SET USERS LIST
    setClientsList(state, action) {
      state.clientsList = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;
export const { setBoard, createColumnSuccess, setCurrentBoard, setUsersList, setClientsList } = actions;

// ----------------------------------------------------------------------

// no se usa
export function getNewBoard() {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    // try {
    // const response = await axios.get('/api/kanban/board');
    // const board = { cards: {}, columns: {}, columnOrder: [] };
    dispatch(setBoard({ currentBoard: initialState.currentBoard }));
    // } catch (error) {
    //   dispatch(slice.actions.hasError(error));
    // }
  };
}

export const sortIdsByDate = (column, conversationIdsSorted) => {
  const cardIdsOriginal = column.cardIds || [];
  const cardIds = conversationIdsSorted.filter((el) => cardIdsOriginal.includes(el));
  return { ...column, cardIds };
};

const getColumnsSorted = (columns, conversationIdsSorted) => {
  if (columns?.one) {
    let columnsToReturn = columns;
    Object.values(columns).forEach((column) => {
      const columnSorted = sortIdsByDate(column, conversationIdsSorted);
      columnsToReturn = {
        ...columnsToReturn,
        [column.id]: columnSorted,
      };
    });
    return columnsToReturn;
  }
  return columns;
};

const validateIsCardInList = (currentBoard, cardId) => {
  const currentCards = Object.keys(currentBoard?.cards || {});
  return currentCards.includes(cardId);
};

const getConversationCards = (conversations, currentBoard) => {
  const cards = {};
  let { cero } = currentBoard?.columns || {};
  // const { one, two, three, four } = currentBoard?.columns || {};

  Object.values(conversations.byId).forEach((el) => {
    if (el.id) {
      const newCard = {
        name: el.userName,
        id: el.id,
        completed: false,
        attachments: {},
        description: '',
        sessionId: el.sessionId || '',
        columnId: currentBoard.cards?.[el.id]?.columnId || 'cero',
      };

      // if (!cero.cardIds) {
      //   cero.cardIds = [];
      // }

      const isCardInList = validateIsCardInList(currentBoard, el.id);
      // console.log('isCardInList', isCardInList);
      if (!isCardInList) {
        // console.log(`${el.id} no estaba en la lista`);
        cero = {
          ...cero,
          cardIds: [...(cero?.cardIds || []), newCard.id],
        };
      }
      // console.log('cards', cards);
      // console.log('newCard', newCard);
      // console.log('el.id', el.id);
      cards[el.id] = newCard;
    }
  });

  // ordenar los ids, el mas frecuente arriba
  const conversationIdsSorted = getChatsToUp(Object.values(conversations.byId), keyBy);
  cero = sortIdsByDate(cero, conversationIdsSorted);
  const { one, two, three, four } = getColumnsSorted(currentBoard?.columns || {}, conversationIdsSorted);
  const newCards = { ...(currentBoard?.cards || {}), ...cards };

  return { cards: newCards, cero, one, two, three, four };
};

const getNewBoardWithCards = (conversations, currentBoard) => {
  // console.log('currentBoard old', currentBoard);
  const { cards, cero, one, two, three, four } = getConversationCards(conversations, currentBoard);
  // console.log('cards', cards);
  // console.log('cero', cero);

  const newBoard = {
    ...currentBoard,
    cards,
    columns: { ...(currentBoard?.columns || {}), cero, one, two, three, four },
  };

  // console.log('newBoard xdd', newBoard);
  return newBoard;
};

export function fetchBoard({ branchId, boardId, conversations, currentSession }) {
  return async (dispatch) => {
    console.log('branchId, boardId, conversations, currentSession', branchId, boardId, conversations, currentSession);
    // dispatch(slice.actions.startLoading());
    // try {
    // console.log('conversations xdd', conversations);
    let board = initialState.currentBoard;
    if (boardId) {
      console.log('fetchBoard 1');
      const boardTemp = await getCurrentBoard(branchId, currentSession.id);
      // console.log('boardTemp', boardTemp);

      board = getNewBoardWithCards(conversations, boardTemp);
    } else {
      console.log('fetchBoard 2');
      const boardTemp = await getCurrentBoard(branchId, currentSession.id);
      // console.log('board', board);
      if (!boardTemp) {
        console.log('fetchBoard 3');
        // console.log('conversations', conversations);
        const newBoard = getNewBoardWithCards(conversations, initialState.currentBoard);
        // console.log('newBoard :v', newBoard);
        board = newBoard; // board = await createBoard(branchId, newBoard, currentSession.id);
      } else {
        console.log('fetchBoard 4');
        board = getNewBoardWithCards(conversations, boardTemp);
      }
    }
    console.log('board createBoard', board);
    board = await createBoard(branchId, board, currentSession.id);

    // console.log('board to set', board);
    dispatch(setBoard({ currentBoard: board, currentSession }));
    // } catch (error) {
    //   dispatch(slice.actions.hasError(error));
    // }
  };
}

// ----------------------------------------------------------------------

export function createColumn(newColumn) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    // try {
    // const response = await axios.post('/api/kanban/columns/new', newColumn);

    dispatch(createColumnSuccess(newColumn));
    // } catch (error) {
    //   dispatch(slice.actions.hasError(error));
    // }
  };
}

// ----------------------------------------------------------------------

// export function updateColumn(columnId, updateColumn) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.post('/api/kanban/columns/update', {
//         columnId,
//         updateColumn,
//       });
//       dispatch(slice.actions.updateColumnSuccess(response.data.column));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// ----------------------------------------------------------------------

// export function deleteColumn(columnId) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       await axios.post('/api/kanban/columns/delete', { columnId });
//       dispatch(slice.actions.deleteColumnSuccess({ columnId }));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

export function clearBoard() {
  return async (dispatch) => {
    dispatch(slice.actions.clear(initialState.currentBoard));
  };
}

// ----------------------------------------------------------------------

export function persistColumn({ newColumnOrder, branchId, currentBoard }) {
  return async (dispatch) => {
    await changeColumnOrder(branchId, currentBoard, newColumnOrder);
    dispatch(slice.actions.persistColumn(newColumnOrder));
  };
}

// ----------------------------------------------------------------------

export function persistCard({ columns, branchId, currentBoard, start, finish, draggableId }) {
  return async (dispatch) => {
    const card = currentBoard.cards[draggableId];
    await changeColumns(branchId, currentBoard, columns, start, finish, card);
    dispatch(slice.actions.persistCard({ columns, card, finish }));
  };
}

// ----------------------------------------------------------------------

export function addTask({ card, columnId, branchId, currentBoard, user }) {
  return async (dispatch) => {
    const cardId = await createCard(branchId, currentBoard, card, columnId);
    // console.log('cardId', cardId);
    const newCard = {
      ...card,
      id: cardId,
      columnId,
    };
    dispatch(slice.actions.addTask({ card: newCard, columnId, currentBoard }));

    await addPointsToUser('CRM', 'create', user, card.name);
  };
}

// ----------------------------------------------------------------------

export function deleteTask({ cardId, columnId, branchId, currentBoard, user, card }) {
  return async (dispatch) => {
    await deleteCard(branchId, currentBoard, cardId, columnId);
    dispatch(slice.actions.deleteTask({ cardId, columnId }));

    await addPointsToUser('CRM', 'delete', user, card.name);
  };
}

// export function addElementInListCard({ name, element, currentBoard, card }) {
//   console.log('currentBoard', currentBoard);
//   const { cards } = currentBoard;
//   const newBoard = {
//     ...currentBoard,
//     cards: {
//       ...cards,
//       [card.id]: {
//         ...card,
//         [name]: [...card[name], element],
//       },
//     },
//   };
//   dispatch(setCurrentBoard(newBoard));
// }

// export function setElementInCard({ name, element, currentBoard, card }) {
//   const { cards } = currentBoard;
//   const newBoard = {
//     ...currentBoard,
//     cards: {
//       ...cards,
//       [card.id]: {
//         ...card,
//         [name]: [...card[name], element],
//       },
//     },
//   };
//   dispatch(setCurrentBoard(newBoard));
// }
