import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  isLoadingMailbox: false,
  mailboxes: [],
  activeMailbox: '',
  emailsList: {
    mailboxId: '',
    emails: []
  },
  mailToExtract: '',
  mailHTML: '',
  mailData: ''
};

const slice = createSlice({
  name: 'mailbox',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    setIsLoadingMailbox(state, action) {
      state.isLoadingMailbox = action.payload;
    },
    setEmailsList(state, action) {
      const { mailboxId, emails } = action.payload;
      state.isLoading = false;
      state.emailsList = { mailboxId, emails };
    },
    setMailboxList(state, action) {
      state.isLoading = false;
      state.mailboxes = action.payload;
    },
    setNewMailbox(state, action) {
      state.isLoading = false;
      state.mailboxes = [...state.mailboxes, action.payload];
    },
    setActiveMailboxLocal(state, action) {
      state.isLoading = false;
      state.activeMailbox = action.payload;
    },
    setMailToExtract(state, action) {
      state.isLoading = false;
      state.mailToExtract = action.payload;
    },
    setMailHtml(state, action) {
      state.isLoading = false;
      state.mailHTML = action.payload;
    },
    setMailData(state, action) {
      state.isLoading = false;
      state.mailData = action.payload;
    },
    updateMailboxesList(state, action) {
      state.mailboxes = action.payload;
    },
    emptyMailboxState(state) {
      state.activeMailbox = '';
      state.emailsList = {
        mailboxId: '',
        emails: []
      };
    }
  }
});

export default slice.reducer;

export const {
  startLoading,
  setIsLoadingMailbox,
  setEmailsList,
  setMailboxList,
  setNewMailbox,
  setMailToExtract,
  setMailHtml,
  setMailData,
  setActiveMailboxLocal,
  updateMailboxesList,
  emptyMailboxState
} = slice.actions;
