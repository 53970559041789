import firebase from 'firebase/app';
import { setCurrentBoard } from '../redux/slices/kanban';
import { dispatch } from '../redux/store';
import { createLog } from '../redux/slices/log';

const waitingRoomBaseUrl = 'waitingRoom_branch';

const getCardBaseUrl = (branchId, currentSessionId, cardId) =>
  `${waitingRoomBaseUrl}/${branchId}/${currentSessionId}/cards/${cardId}`;

const getColumnBaseUrl = (branchId, currentSessionId, columnId) =>
  `${waitingRoomBaseUrl}/${branchId}/${currentSessionId}/columns/${columnId}`;

export async function getCurrentBoard(branchId, currentSessionId) {
  const rootRef = await firebase
    .database()
    .ref(`/${waitingRoomBaseUrl}/${branchId}/${currentSessionId}`)
    .once('value')
    .then((snap) => snap.val());
  return rootRef;
}

export const createBoard = async (branchId, board, currentSessionId) => {
  const boardRef = firebase.database().ref(`/${waitingRoomBaseUrl}/${branchId}/${currentSessionId}`);
  // const updates = {};
  // updates[`/${waitingRoomBaseUrl}/${branchId}/${boardId}`] = { id: boardId, ...board };
  // await firebase.database().ref().update(updates);
  // console.log('create board', boardId);
  const newBoard = {
    ...board,
    id: branchId,
    currentSessionId,
  };
  await boardRef.set(newBoard);
  return newBoard;
};

// export const updateBoard = async (branchId, board) => {
//   const updates = {};
//   updates[`/${waitingRoomBaseUrl}/${branchId}/${board.id}`] = board;
//   await firebase.database().ref().update(updates);
//   console.log('update board', board.id);
//   return board.id;
// };

export const createCard = async (branchId, currentBoard, card, columnId, currentSessionId) => {
  // state.currentBoard.cards[card.id] = card;
  // const boardId = currentBoard.id;
  const cardId = firebase.database().ref(`/${waitingRoomBaseUrl}/${branchId}/${currentSessionId}`).push().key;
  const updates = {};
  const cardBaseUrl = getCardBaseUrl(branchId, currentSessionId, cardId);
  updates[`/${cardBaseUrl}`] = { ...card, id: cardId, columnId };

  // state.currentBoard.columns[columnId].cardIds.push(card.id);
  const cardIds = [...(currentBoard.columns[columnId]?.cardIds || []), cardId];

  // const cardIds = currentBoard.columns[columnId]?.cardIds || [];
  // console.log('cardIds',cardIds)
  // cardIds.push(cardId);

  const columnBaseUrl = getColumnBaseUrl(branchId, currentSessionId, columnId);
  updates[`/${columnBaseUrl}/cardIds`] = cardIds;

  await firebase.database().ref().update(updates);

  await createLog('project', 'create', `${cardId}`, [], card);
  return cardId;
};

export const updateCard = async (branchId, currentSessionId, card, oldCard = []) => {
  const log = await createLog('project', 'update', `${card.id}`, oldCard, card);

  const currentBoard = await getCurrentBoard(branchId, currentSessionId);
  // console.log('currentBoard', currentBoard);

  // const cardBaseUrl = getCardBaseUrl(branchId, card.id);
  // updates[`/${cardBaseUrl}`] = card;
  // `${waitingRoomBaseUrl}/${branchId}/cards/${cardId}`

  const newBoard = {
    ...currentBoard,
    cardLogs: { ...currentBoard?.cardLogs, [log]: true },
    cards: {
      ...(currentBoard?.cards || {}),
      [card.id]: card,
    },
  };

  const updates = {};
  updates[`/${waitingRoomBaseUrl}/${branchId}/${currentSessionId}`] = newBoard;

  await firebase.database().ref().update(updates);
  dispatch(setCurrentBoard(newBoard));
};

export const deleteCard = async (branchId, currentBoard, cardId, columnId) => {
  // const boardId = currentBoard.id;
  // state.currentBoard.columns[columnId].cardIds = state.currentBoard.columns[columnId].cardIds.filter(
  //   (id) => id !== cardId
  // );
  const updates = {};
  const columnBaseUrl = getColumnBaseUrl(branchId, currentBoard.currentSessionId, columnId);
  const { cardIds } = currentBoard.columns[columnId];
  const newList = cardIds.filter((id) => id !== cardId);

  updates[`/${columnBaseUrl}/cardIds`] = newList;
  await firebase.database().ref().update(updates);

  // state.currentBoard.cards = omit(state.currentBoard.cards, [cardId]);
  const cardBaseUrl = getCardBaseUrl(branchId, currentBoard.currentSessionId, cardId);
  const rootRef = firebase.database().ref(`/${cardBaseUrl}`);
  await rootRef.remove();
};

// newColumnOrder
export const changeColumnOrder = async (branchId, currentBoard, newColumnOrder) => {
  const boardUrl = `${waitingRoomBaseUrl}/${branchId}/${currentBoard.currentSessionId}/columnOrder`;
  const updates = {};
  updates[`/${boardUrl}`] = newColumnOrder;
  await firebase.database().ref().update(updates);
};

// persistCard
export const changeColumns = async (branchId, currentBoard, columns, start, finish, card) => {
  await createLog('project', 'moved', `${card.id}`, { progress: start.name }, { progress: finish.name });

  const boardUrl = `${waitingRoomBaseUrl}/${branchId}/${currentBoard.currentSessionId}/columns`;
  const cardUrl = `${waitingRoomBaseUrl}/${branchId}/${currentBoard.currentSessionId}/cards/${card.id}`;
  const updates = {};
  updates[`/${boardUrl}`] = columns;
  updates[`/${cardUrl}/columnId`] = finish.id;
  await firebase.database().ref().update(updates);
};

export const getCards = async (branchId, currentBoard) => {
  const data = await firebase
    .database()
    .ref(`/${waitingRoomBaseUrl}/${branchId}/${currentBoard.currentSessionId}/cards`)
    .once('value')
    .then((snap) => snap.val());
  return data;
};

export const getCardById = async (branchId, currentBoard, cardId) => {
  const data = await firebase
    .database()
    .ref(`/${waitingRoomBaseUrl}/${branchId}/${currentBoard.currentSessionId}/cards/${cardId}`)
    .once('value')
    .then((snap) => snap.val());
  return data;
};

// export const addDocumentToCard = async (branchId, cardId, doc) => {
//   const cardBaseUrl = getCardBaseUrl(branchId, cardId);
//   const currentCard = await getCardById(branchId, cardId);

//   const newCard = {
//     ...currentCard,
//     documents: [...(currentCard?.documents || []), doc],
//   };

//   const updates = {};
//   updates[`/${cardBaseUrl}`] = newCard;
//   await firebase.database().ref().update(updates);
// };

const setCard = (name, element, currentBoard, card) => {
  const { cards } = currentBoard;
  const newBoard = {
    ...currentBoard,
    cards: {
      ...cards,
      [card.id]: {
        ...card,
        [name]: { ...(card[name] || {}), [element.id]: element },
      },
    },
  };
  // console.log('newBoard', newBoard);
  dispatch(setCurrentBoard(newBoard));
};

export const addFileOnCard = async (branchId, cardId, file, currentBoard, card) => {
  const cardBaseUrl = getCardBaseUrl(branchId, currentBoard.currentSessionId, cardId);
  const updates = {};
  updates[`/${cardBaseUrl}/files_path/${file.id}`] = file;
  await firebase.database().ref().update(updates);

  setCard('files_path', file, currentBoard, card);
};

export const getUrlFileOfAnAttachment = async (branchId, cardId, idEncoded) => {
  const cardBaseUrl = getCardBaseUrl(branchId, cardId);

  const rootRef = await firebase
    .database()
    .ref(`/${cardBaseUrl}/files_path/${idEncoded}`)
    .once('value')
    .then((snap) => snap.val());
  return rootRef;
};

const asignCardIntoColumn = async (branchId, currentBoard, card, columnId) => {
  const sessionId = currentBoard?.currentSessionId || '';
  console.log('asignCardIntoColumn currentBoard', currentBoard);
  console.log('sessionId', sessionId);
  const updates = {};
  // const columnId = newQueue.columnOrder[0];

  // add conversation card in queue board
  const newCard = {
    ...card,
    columnId,
  };

  const cardBaseUrl = getCardBaseUrl(branchId, sessionId, newCard.id);
  const columnBaseUrl = getColumnBaseUrl(branchId, sessionId, columnId);
  console.log('cardBaseUrl asignCardIntoColumn', cardBaseUrl);
  updates[cardBaseUrl] = newCard;

  const cardIds = [...(currentBoard.columns[columnId].cardIds || []), newCard.id];
  updates[`${columnBaseUrl}/cardIds`] = cardIds;

  // update current queue id
  // updates[`${whatsappApiBaseUrl}/currentQueueId`] = currentBoard.id;
  // await firebase.database().ref().update(updates);

  await firebase.database().ref().update(updates);
  // await moveCardIntoColumn(branchId, card, sessions);
};

const desvinculateCardFromColumn = async (branchId, currentBoard, card) => {
  const sessionId = currentBoard?.currentSessionId || '';
  const cardId = card.id; // currentConversation.id;
  const columnId = card.columnId || ''; // oldQueue.columnOrder[0];
  const columnBaseUrl = getColumnBaseUrl(branchId, sessionId, columnId);
  const cardIds = (currentBoard.columns[columnId].cardIds || []).filter((el) => el !== cardId);
  console.log('cardIds nuevo', cardIds);

  const updates = {};
  updates[`${columnBaseUrl}/cardIds`] = cardIds;
  await firebase.database().ref().update(updates);
};

// const desvinculateCardFromColumn = async (branchId, currentBoard, card) => {
//   const sessionId = currentBoard?.currentSessionId || '';
//   const cardId = card.id; // currentConversation.id;
//   // const card = await getCardById(branchId, { currentSessionId: sessionId }, cardId);
//   console.log('card desvinculateCardFromColumn', card);

//   // delete conversation card in queue board
//   // test
//   const updates = {};
//   // const cardBaseUrl = getCardBaseUrl(branchId, sessionId, cardId);
//   // updates[`${cardBaseUrl}`] = null;

//   // revisar el desvincular, eliminar de la columna id,
//   // si no actualizo el estado no cambia currentQueueId
//   // const oldQueue = await getQueueById(branchId, queueId);
//   // console.log('oldQueue', oldQueue);
//   const columnId = card.columnId || ''; // oldQueue.columnOrder[0];
//   console.log('columnId', columnId);
//   console.log('sessionId', sessionId);
//   const columnBaseUrl = getColumnBaseUrl(branchId, sessionId, columnId);
//   // console.log('oldQueue.columns[columnId].cardIds viejo xd', oldQueue.columns[columnId].cardIds);
//   console.log('columnBaseUrl', columnBaseUrl);
//   console.log('columnId', columnId);
//   console.log('cardId', cardId);
//   console.log('currentBoard xd', currentBoard);
//   // const cardIds = (currentBoard.columns[columnId].cardIds || []).filter((el) => el !== cardId);
//   // const cardIds = ['50662576402', '50683363008', '50660333133', '50670102332', '50660926826', '50686395762',"523511571496","50688425300"];
//   const cardIds = ['50683363008'];
//   console.log('cardIds nuevo', cardIds);
//   updates[`${columnBaseUrl}/cardIds`] = cardIds;

//   console.log('updates', updates);
//   await firebase
//     .database()
//     .ref()
//     .update(updates)
//     .then(() => console.log('Update successful'))
//     .catch((error) => console.error('Error updating Firebase:', error)); // Capturar posibles errores

//   // xdddddd
//   const xd = await getCurrentBoard(branchId, sessionId);
//   console.log('avr si no actualiza', xd);
// };

export const moveCardIntoColumn = async (branchId, card, sessions, moveWaitingRoomCardFromColumn) => {
  // await changeColumns(branchId, currentBoard, columns, start, finish, card);
  if (card?.columnId === 'cero') {
    console.log('moveCardIntoColumn en cero');
    // se mueve a la uno y listo
    // const currentBoard = {};
    console.log('card', card);
    console.log('card.sessionId', card.sessionId);
    if (card.sessionId) {
      const sessionFinded = sessions.find((el) => el.phoneNumber === card.sessionId); // card.sessionId es el phoneNumber
      const currentBoard = await getCurrentBoard(branchId, sessionFinded.id);
      // console.log('currentBoard', currentBoard);
      // await desvinculateCardFromColumn(branchId, currentBoard, card);
      // await asignCardIntoColumn(branchId, currentBoard, card, 'one');

      moveWaitingRoomCardFromColumn(currentBoard, currentBoard.columns.cero, currentBoard.columns.one, card.id);
    }
  }
};
