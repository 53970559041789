import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import { getCompanyById } from './companies';
import { createLog } from '../redux/slices/log';

export const getBranchesByCompany = async (company) => {
  const branches = [];
  const branchesIds = Array.isArray(company.branches) ? company.branches : [];

  await Promise.all(
    branchesIds.map(async (id) => {
      await firebase
        .database()
        .ref(`branches/${id}`)
        .once('value')
        .then((snap) => {
          const snapData = snap.val();
          const branch = {
            ...snapData,
            id: snap.key
          };
          if (branch.name) {
            branches.push(branch);
          }
        });
    })
  );

  return branches;
};

export const getBranchById = async (id) => {
  let fetchedBranch = {};

  await firebase
    .database()
    .ref(`branches/${id}`)
    .once('value')
    .then((snap) => {
      const snapData = snap.val();
      const branch = {
        ...snapData,
        id: snap.key
      };

      fetchedBranch = branch;
    });

  return fetchedBranch;
};

export const createNewBranch = async (branch) => {
  const rootRef = firebase.database().ref('/');
  const companies = rootRef.child('companies');
  const branches = rootRef.child('branches');
  const parentCompanyRef = companies.child(branch.parentCompanyId);
  const parentCompany = await getCompanyById(branch.parentCompanyId);

  const newBranchBody = {
    ...branch,
    authorizedUsersIds:
      branch.rootUser === parentCompany.rootUser
        ? branch.authorizedUsersIds
        : [branch.rootUser, parentCompany.rootUser]
  };

  const newBranch = await branches.push(newBranchBody);

  const companyBranches = parentCompany.branches?.filter((item) => item) || [];
  const updatedParentCompany = {
    ...parentCompany,
    branches: [...companyBranches, newBranch.key]
  };

  await parentCompanyRef.update(updatedParentCompany);
};

export const updateBranch = async (branch, currentUserId) => {
  const currentBranch = await getBranchById(branch.id);
  const log = await createLog('branch', 'edit', branch.id, currentBranch, branch);

  const rootRef = firebase.database().ref('/');
  const branches = rootRef.child('branches');
  const currentBranchRef = branches.child(branch.id);

  const newBranch= {
    ...branch,
    log: [...(branch.log || []), log],
  };
  await currentBranchRef.update(newBranch);
};

export const deleteBranch = async (branch) => {
  const rootRef = firebase.database().ref('/');
  const branches = rootRef.child('branches');
  const companies = rootRef.child('companies');
  const parentCompanyRef = companies.child(branch.parentCompanyId);
  const parentCompany = await getCompanyById(branch.parentCompanyId);

  // delete the this branch from parent company
  const nonRemovedBranches = parentCompany.branches.filter(
    (item) => item !== branch.id
  );
  const updatedParentCompany = {
    ...parentCompany,
    branches: nonRemovedBranches
  };
  await parentCompanyRef.update(updatedParentCompany);

  // delete branch
  const currentBranchRef = branches.child(branch.id);
  currentBranchRef.remove();
};
