import { lazy } from 'react';
import { Redirect } from 'react-router-dom';
// guards
import AuthGuard from '../guards/AuthGuard';
// layouts
import DashboardLayout from '../layouts/dashboard';
//
import { PATH_DASHBOARD } from './paths';

// ----------------------------------------------------------------------

const DashboardRoutes = {
  path: PATH_DASHBOARD.root,
  guard: AuthGuard,
  layout: DashboardLayout,
  routes: [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_DASHBOARD.root,
      component: () => <Redirect to={PATH_DASHBOARD.user.profile} />,
    },
    {
      exact: true,
      path: PATH_DASHBOARD.general.general,
      component: lazy(() => import('../views/GeneralApp')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.general.organization.root,
      component: lazy(() => import('../views/Organization/index')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.general.organization.create,
      component: lazy(() => import('../views/Organization/OrganizationRegistration')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.general.organization.edit,
      component: lazy(() => import('../views/Organization/OrganizationModification')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.general.organization.duplicate,
      component: lazy(() => import('../views/Organization/OrganizationDuplication')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.general.user.list,
      component: lazy(() => import('../views/UserList')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.general.user.create,
      component: lazy(() => import('../views/UserCreate')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.general.user.detail,
      component: lazy(() => import('../views/UserDetail')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.general.user.edit,
      component: lazy(() => import('../views/UserEdit')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.general.user.duplicate,
      component: lazy(() => import('../views/UserDuplicate')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.general.user.invite,
      component: lazy(() => import('../views/User/UserInvite')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.general.role.create,
      component: lazy(() => import('../views/General/Roles/RoleCreate')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.general.role.detail,
      component: lazy(() => import('../views/General/Roles/RoleDetail')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.general.role.edit,
      component: lazy(() => import('../views/General/Roles/RoleEdit')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.general.role.duplicate,
      component: lazy(() => import('../views/General/Roles/RoleDuplicate')),
    },
    // User
    {
      exact: true,
      path: PATH_DASHBOARD.user.root,
      component: () => <Redirect to={PATH_DASHBOARD.user.profile} />,
    },
    {
      exact: true,
      path: PATH_DASHBOARD.administration.management,
      component: lazy(() => import('../views/Administration/Management/index')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.user.profile,
      component: lazy(() => import('../views/UserProfile')),
    },
    // COMPANY
    {
      exact: true,
      path: PATH_DASHBOARD.administration.company.list,
      component: lazy(() => import('../views/Company')),
    },
    // Branch
    {
      exact: true,
      path: PATH_DASHBOARD.administration.branch.list,
      component: lazy(() => import('../views/Branch')),
    },
    // Client Edit
    {
      exact: true,
      path: PATH_DASHBOARD.client.edit,
      component: lazy(() => import('../views/Client/EditClient/index')),
    },
    // Client detail
    {
      exact: true,
      path: PATH_DASHBOARD.client.detail,
      component: lazy(() => import('../views/Client/EditClient/index')),
    },
    // Client Create
    {
      exact: true,
      path: PATH_DASHBOARD.client.create,
      component: lazy(() => import('../views/Client/CreateClient/index')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.client.create2,
      component: lazy(() => import('../views/Client/CreateClient/index')),
    },
    // Client Clone
    {
      exact: true,
      path: PATH_DASHBOARD.client.clone,
      component: lazy(() => import('../views/Client/CloneClient/index')),
    },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.client.newClient,
    //   component: lazy(() => import('../views/Branch/NewClient/index')),
    // },

    // Contacts || Clients
    {
      exact: true,
      path: PATH_DASHBOARD.client.root,
      component: lazy(() => import('../views/Administration/Contacts')),
    },
    // Client Massive upload
    {
      exact: true,
      path: PATH_DASHBOARD.client.massiveUpload,
      component: lazy(() => import('../views/Administration/Contacts/ContactMassiveUpload')),
    },
    // Articles
    {
      exact: true,
      path: PATH_DASHBOARD.administration.article.articles,
      component: lazy(() => import('../views/Administration/Articles')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.administration.article.create,
      component: lazy(() => import('../views/Administration/Articles/ArticleRegistration')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.administration.article.detail,
      component: lazy(() => import('../views/Administration/Articles/ArticleDetails')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.administration.article.massiveUpload,
      component: lazy(() => import('../views/Administration/Articles/ArticleMassiveUpload')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.administration.article.edit,
      component: lazy(() => import('../views/Administration/Articles/ArticleModification')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.administration.article.duplicate,
      component: lazy(() => import('../views/Administration/Articles/ArticleDuplication')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.general.ecommerce,
      component: lazy(() => import('../views/GeneralEcommerce')),
    },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.general.analytics,
    //   component: lazy(() => import('../views/GeneralAnalytics'))
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.general.app,
    //   component: lazy(() => import('../views/GeneralApp'))
    // },
    {
      exact: true,
      path: PATH_DASHBOARD.email.labels,
      component: lazy(() => import('../views/Email')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.email.root,
      component: () => <Redirect to={PATH_DASHBOARD.email.all} />,
    },

    // // ADMINISTRATION
    // // ----------------------------------------------------------------------

    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.administration.banks.root,
    //   component: lazy(() => import('../views/Administration/Banks'))
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.administration.banks.createBank,
    //   component: lazy(() =>
    //     import('../views/Administration/Banks/BankRegistration')
    //   )
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.administration.banks.modifyBank,
    //   component: lazy(() =>
    //     import('../views/Administration/Banks/BankModification')
    //   )
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.administration.banks.duplicateBank,
    //   component: lazy(() =>
    //     import('../views/Administration/Banks/BankDuplication')
    //   )
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.administration.banks.bankDetail,
    //   component: lazy(() => import('../views/Administration/Banks/BankDetails'))
    // },

    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.administration.accounting,
    //   component: lazy(() => import('../views/Administration/Articles/index'))
    // },
    {
      exact: true,
      path: PATH_DASHBOARD.administration.inventory,
      component: lazy(() => import('../views/Administration/Inventory/index')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.administration.inventoryMassiveUpload,
      component: lazy(() => import('../views/Administration/Inventory/InventoryMassiveUpload')),
    },
    // // ACCOUNTING
    // // ----------------------------------------------------------------------
    {
      // CURRENCY
      exact: true,
      path: PATH_DASHBOARD.accounting.currency.root,
      component: lazy(() => import('../views/Accounting/Currency/index')),
    },
    {
      // BANKS Accounts
      exact: true,
      path: PATH_DASHBOARD.accounting.bankAccount.root,
      component: lazy(() => import('../views/Accounting/BankAccount/index')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.accounting.bankAccount.create,
      component: lazy(() => import('../views/Accounting/BankAccount/BankAccountRegistration')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.accounting.bankAccount.edit,
      component: lazy(() => import('../views/Accounting/BankAccount/BankAccountModification')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.accounting.bankAccount.detail,
      component: lazy(() => import('../views/Accounting/BankAccount/BankAccountDetails')),
    },
    // // LEGAL
    // // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_DASHBOARD.legal.process.root,
      component: lazy(() => import('../views/Legal/Process/index')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.legal.process.create,
      component: lazy(() => import('../views/Legal/Process/NewProcess')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.legal.process.detail,
      component: lazy(() => import('../views/Legal/Process/ProcessDetails')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.legal.procedure.procedures,
      component: lazy(() => import('../views/Legal/Procedures/index')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.legal.procedure.create,
      component: lazy(() => import('../views/Legal/Procedures/ProcedureRegistration')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.legal.procedure.detail,
      component: lazy(() => import('../views/Legal/Procedures/ProcedureDetails')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.legal.procedure.massiveUpload,
      component: lazy(() => import('../views/Legal/Procedures/ProcedureMassiveUpload')),
    },

    {
      exact: true,
      path: PATH_DASHBOARD.legal.procedure.edit,
      component: lazy(() => import('../views/Legal/Procedures/ProcedureModification')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.legal.procedure.duplicate,
      component: lazy(() => import('../views/Legal/Procedures/ProcedureDuplication')),
    },
    {
      // calculator
      exact: true,
      path: PATH_DASHBOARD.legal.procedure.calculate,
      component: lazy(() => import('../views/Legal/Calculator/index')),
    },
    {
      // quote
      exact: true,
      path: PATH_DASHBOARD.legal.quote.quotes,
      component: lazy(() => import('../views/Legal/Quotes/index')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.legal.quote.create,
      component: lazy(() => import('../views/Legal/Quotes/QuoteRegistration')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.legal.quote.detail,
      component: lazy(() => import('../views/Legal/Quotes/QuoteDetails')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.legal.quote.edit,
      component: lazy(() => import('../views/Legal/Quotes/QuoteModification')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.legal.quote.duplicate,
      component: lazy(() => import('../views/Legal/Quotes/QuoteDuplication')),
    },
    {
      // crm
      exact: true,
      path: PATH_DASHBOARD.legal.crm.root,
      component: lazy(() => import('../views/Legal/CRM/index')),
    },
    // projects family
    {
      exact: true,
      path: PATH_DASHBOARD.operations.project.root,
      component: lazy(() => import('../views/Operations/Projects/FamilyList')),
    },
    // project board
    {
      exact: true,
      path: PATH_DASHBOARD.operations.project.board,
      component: lazy(() => import('../views/Operations/Projects/FamilyBoard')),
    },
    // Waiting Room
    {
      exact: true,
      path: PATH_DASHBOARD.comunications.waitigRoom,
      component: lazy(() => import('../views/Comunication/WaitingRoom/index')),
    },
    // Whatsapp queues
    {
      exact: true,
      path: PATH_DASHBOARD.comunications.queues,
      component: lazy(() => import('../views/Comunication/Queues/index')),
    },
    // APP : whatsapp Chat
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_DASHBOARD.whatsappChat.conversation,
      component: lazy(() => import('../views/Chat/Whatsapp/index')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.whatsappChat.test,
      component: lazy(() => import('../views/Chat/Whatsapp/Chat')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.whatsappChat.testConversation,
      component: lazy(() => import('../views/Chat/Whatsapp/Chat')),
    },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.whatsappChat.root,
    //   component: lazy(() => import('../views/Chat/Whatsapp/Chat')),
    // },
    {
      exact: true,
      path: PATH_DASHBOARD.whatsappChat.root,
      component: () => <Redirect to={PATH_DASHBOARD.whatsappChat.new} />,
    },
    // // APIWHATSAPP
    // // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_DASHBOARD.apiWhatsapp.conversation,
      component: lazy(() => import('../views/Chat/WhatsappAPI/index')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.apiWhatsapp.root,
      component: () => <Redirect to={PATH_DASHBOARD.apiWhatsapp.new} />,
    },
    // // WHATSAPP DEFAULT
    // // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_DASHBOARD.whatsappDefault.conversation,
      component: lazy(() => import('../views/Chat/DefaultWhatsapp/index')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.whatsappDefault.root,
      component: () => <Redirect to={PATH_DASHBOARD.whatsappDefault.new} />,
    },
    // VAPI
    {
      exact: true,
      path: PATH_DASHBOARD.general.vapi.root,
      component: lazy(() => import('../views/vapi/index')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.general.vapi.create,
      component: lazy(() => import('../views/vapi/createasis')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.general.vapi.edit,
      component: lazy(() => import('../views/vapi/editasis')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.general.vapinum.root,
      component: lazy(() => import('../views/vapi/numeros')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.general.vapidoc.root,
      component: lazy(() => import('../views/vapi/documentos')),
    },
    // // PAYMENT
    // // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_DASHBOARD.payment.root,
      component: lazy(() => import('../views/Payment/index')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.payment.methods,
      component: lazy(() => import('../views/Payment/PaymentMethod/index')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.payment.settings,
      component: lazy(() => import('../views/Payment/PaymentSettings/index')),
    },
    // // INVENTARIO : TRANSACCIONES
    // // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_DASHBOARD.inventory.transactions,
      component: lazy(() => import('../views/Administration/Inventory/InventoryTransactions/index')),
    },

    // // ADMINISTRATION : VENTAS
    // // ----------------------------------------------------------------------
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.sales.quotes,
    //   component: lazy(() =>
    //     import('../views/Administration/Sales/SalesInvoices')
    //   )
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.sales.salesOrders,
    //   component: lazy(() =>
    //     import('../views/Administration/Sales/SalesInvoices')
    //   )
    // },
    {
      exact: true,
      path: PATH_DASHBOARD.sales.salesInvoices,
      component: lazy(() => import('../views/Administration/Sales/SalesInvoices')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.sales.salesInvoiceDetail,
      component: lazy(() => import('../views/Administration/Sales/SalesInvoices/SalesInvoiceDetails')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.sales.create,
      component: lazy(() => import('../views/Administration/Sales/SalesInvoices/SalesInvoiceRegistration')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.sales.modifySalesInvoice,
      component: lazy(() => import('../views/Administration/Sales/SalesInvoices/SalesInvoiceModification')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.sales.duplicateSalesInvoice,
      component: lazy(() => import('../views/Administration/Sales/SalesInvoices/SalesInvoiceDuplication')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.sales.salesInvoiceEmail,
      component: lazy(() => import('../views/Administration/Sales/SalesInvoices/SalesInvoiceEmail')),
    },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.sales.recurringBills,
    //   component: lazy(() =>
    //     import('../views/Administration/Sales/SalesInvoices')
    //   )
    // },
    {
      exact: true,
      path: PATH_DASHBOARD.sales.paymentsReceived,
      component: lazy(() => import('../views/Administration/Sales/SalesInvoices')),
    },
    // Credit notes
    {
      exact: true,
      path: PATH_DASHBOARD.sales.creditNotes.list,
      component: lazy(() => import('../views/Administration/Purchases/CreditNotes/CreditNotesList')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.sales.creditNotes.create,
      component: lazy(() => import('../views/Administration/Purchases/CreditNotes/CreditNoteCreate')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.sales.creditNotes.detail,
      component: lazy(() => import('../views/Administration/Purchases/CreditNotes/CreditNoteDetails')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.sales.creditNotes.edit,
      component: lazy(() => import('../views/Administration/Purchases/CreditNotes/CreditNoteEdit')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.sales.creditNotes.duplicate,
      component: lazy(() => import('../views/Administration/Purchases/CreditNotes/CreditNoteDuplicate')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.sales.creditNotes.email,
      component: lazy(() => import('../views/Administration/Purchases/CreditNotes/CreditNoteEmail')),
    },
    // BUDGET
    {
      exact: true,
      path: PATH_DASHBOARD.sales.budget.root,
      component: lazy(() => import('../views/Administration/Sales/Budget/index')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.sales.budget.create,
      component: lazy(() => import('../views/Administration/Sales/Budget/BudgetRegistration')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.sales.budget.detail,
      component: lazy(() => import('../views/Administration/Sales/Budget/BudgetDetails')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.sales.budget.edit,
      component: lazy(() => import('../views/Administration/Sales/Budget/BudgetModification')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.sales.budget.duplicate,
      component: lazy(() => import('../views/Administration/Sales/Budget/BudgetDuplication')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.sales.budget.email,
      component: lazy(() => import('../views/Administration/Sales/Budget/BudgetEmail')),
    },
    // RECURRENT INVOICES
    {
      exact: true,
      path: PATH_DASHBOARD.sales.recurrentInvoice.root,
      component: lazy(() => import('../views/Administration/Sales/RecurrentInvoice/index')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.sales.recurrentInvoice.create,
      component: lazy(() => import('../views/Administration/Sales/RecurrentInvoice/RecurrentInvoiceRegistration')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.sales.recurrentInvoice.detail,
      component: lazy(() => import('../views/Administration/Sales/RecurrentInvoice/RecurrentInvoiceDetails')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.sales.recurrentInvoice.edit,
      component: lazy(() => import('../views/Administration/Sales/RecurrentInvoice/RecurrentInvoiceModification')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.sales.recurrentInvoice.duplicate,
      component: lazy(() => import('../views/Administration/Sales/RecurrentInvoice/RecurrentInvoiceDuplication')),
    },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.sales.root,
    //   component: () => <Redirect to={PATH_DASHBOARD.sales.quotes} />
    // },

    // ADMINISTRATION : COMPRAS
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_DASHBOARD.purchases.expense.root,
      component: lazy(() => import('../views/Administration/Purchases/Expenses/index')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.purchases.expense.create,
      component: lazy(() => import('../views/Administration/Purchases/Expenses/ExpenseRegistration')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.purchases.expense.edit,
      component: lazy(() => import('../views/Administration/Purchases/Expenses/ExpenseModification')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.purchases.expense.duplicate,
      component: lazy(() => import('../views/Administration/Purchases/Expenses/ExpenseDuplication')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.purchases.expense.detail,
      component: lazy(() => import('../views/Administration/Purchases/Expenses/ExpenseDetails')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.purchases.recurringExpenses,
      component: lazy(() => import('../views/BlogPosts')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.purchases.purchaseOrders,
      component: lazy(() => import('../views/Administration/Purchases/PurchaseOrders/index')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.purchases.createPurchaseOrder,
      component: lazy(() => import('../views/Administration/Purchases/PurchaseOrders/PurchaseOrderRegistration')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.purchases.modifyPurchaseOrder,
      component: lazy(() => import('../views/Administration/Purchases/PurchaseOrders/PurchaseOrderModification')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.purchases.duplicatePurchaseOrder,
      component: lazy(() => import('../views/Administration/Purchases/PurchaseOrders/PurchaseOrderDuplication')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.purchases.detailPurchaseOrder,
      component: lazy(() => import('../views/Administration/Purchases/PurchaseOrders/PurchaseOrderDetails')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.purchases.supplierInvoices.root,
      component: lazy(() => import('../views/Administration/Purchases/SupplierInvoices/index')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.purchases.supplierInvoices.create,
      component: lazy(() => import('../views/Administration/Purchases/SupplierInvoices/SupplierInvoiceRegistration')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.purchases.supplierInvoices.edit,
      component: lazy(() => import('../views/Administration/Purchases/SupplierInvoices/SupplierInvoiceModification')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.purchases.supplierInvoices.duplicate,
      component: lazy(() => import('../views/Administration/Purchases/SupplierInvoices/SupplierInvoiceDuplication')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.purchases.supplierInvoices.detail,
      component: lazy(() => import('../views/Administration/Purchases/SupplierInvoices/SupplierInvoiceDetails')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.purchases.recurringSupplierInvoices,
      component: lazy(() => import('../views/BlogPosts')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.purchases.paymentsToSuppliers,
      component: lazy(() => import('../views/BlogPosts')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.purchases.supplierCredits,
      component: lazy(() => import('../views/BlogPosts')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.purchases.root,
      component: () => <Redirect to={PATH_DASHBOARD.purchases.expenses} />,
    },
    // MANAGEMENT : E-COMMERCE
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_DASHBOARD.eCommerce.shop,
      component: lazy(() => import('../views/EcommerceShop')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.eCommerce.product,
      component: lazy(() => import('../views/EcommerceProductDetails')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.eCommerce.list,
      component: lazy(() => import('../views/EcommerceProductList')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.eCommerce.checkout,
      component: lazy(() => import('../views/EcommerceCheckout')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.eCommerce.invoice,
      component: lazy(() => import('../views/EcommerceInvoice')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.eCommerce.root,
      component: () => <Redirect to={PATH_DASHBOARD.eCommerce.shop} />,
    },
    // SET UP USERS
    {
      exact: true,
      path: PATH_DASHBOARD.setup.users.root,
      component: lazy(() => import('../views/SetUp/Users')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.setup.users.detail,
      component: lazy(() => import('../views/SetUp/Users/UserDetails')),
    },
    // SET UP NEWS
    {
      exact: true,
      path: PATH_DASHBOARD.setup.news.root,
      component: lazy(() => import('../views/SetUp/News')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.setup.news.create,
      component: lazy(() => import('../views/SetUp/News/NewsRegistration')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.setup.news.detail,
      component: lazy(() => import('../views/SetUp/News/NewsDetails')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.setup.news.edit,
      component: lazy(() => import('../views/SetUp/News/NewsModification')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.setup.news.duplicate,
      component: lazy(() => import('../views/SetUp/News/NewsDuplication')),
    },
    // SET UP ORGANIZATIONS
    {
      exact: true,
      path: PATH_DASHBOARD.setup.organizations.root,
      component: lazy(() => import('../views/SetUp/Organizations')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.setup.organizations.detail,
      component: lazy(() => import('../views/SetUp/Organizations/OrganizationDetails')),
    },
    // SET UP TAXES
    {
      exact: true,
      path: PATH_DASHBOARD.setup.taxes.root,
      component: lazy(() => import('../views/SetUp/Taxes/index')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.setup.taxes.create,
      component: lazy(() => import('../views/SetUp/Taxes/TaxRegistration')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.setup.taxes.detail,
      component: lazy(() => import('../views/SetUp/Taxes/TaxDetails')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.setup.taxes.edit,
      component: lazy(() => import('../views/SetUp/Taxes/TaxModification')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.setup.taxes.duplicate,
      component: lazy(() => import('../views/SetUp/Taxes/TaxDuplication')),
    },
    // SET UP RATING
    {
      exact: true,
      path: PATH_DASHBOARD.setup.ratings.root,
      component: lazy(() => import('../views/SetUp/Rating/index')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.setup.ratings.create,
      component: lazy(() => import('../views/SetUp/Rating/RatingRegistration')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.setup.ratings.detail,
      component: lazy(() => import('../views/SetUp/Rating/RatingDetails')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.setup.ratings.edit,
      component: lazy(() => import('../views/SetUp/Rating/RatingModification')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.setup.ratings.duplicate,
      component: lazy(() => import('../views/SetUp/Rating/RatingDuplication')),
    },
    // SET UP monitoring
    {
      exact: true,
      path: PATH_DASHBOARD.setup.monitoring.root,
      component: lazy(() => import('../views/SetUp/Monitoring/index')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.setup.monitoring.create,
      component: lazy(() => import('../views/SetUp/Monitoring/RatingRegistration')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.setup.monitoring.detail,
      component: lazy(() => import('../views/SetUp/Monitoring/RatingDetails')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.setup.monitoring.edit,
      component: lazy(() => import('../views/SetUp/Monitoring/RatingModification')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.setup.monitoring.duplicate,
      component: lazy(() => import('../views/SetUp/Monitoring/RatingDuplication')),
    },
    // MANAGEMENT : BLOG
    // // ----------------------------------------------------------------------
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.blog.root,
    //   component: lazy(() => import('../views/BlogPosts'))
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.blog.post,
    //   component: lazy(() => import('../views/BlogPost'))
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.blog.newPost,
    //   component: lazy(() => import('../views/BlogNewPost'))
    // },

    // // MANAGEMENT : USER
    // // ----------------------------------------------------------------------
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.user.profile,
    //   component: lazy(() => import('../views/UserProfile'))
    // },

    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.user.cards,
    //   component: lazy(() => import('../views/UserCards'))
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.user.list,
    //   component: lazy(() => import('../views/UserList'))
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.user.root,
    //   component: () => <Redirect to={PATH_DASHBOARD.user.profile} />
    // },

    // // APP : CHAT
    // // ----------------------------------------------------------------------
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.chat.conversation,
    //   component: lazy(() => import('../views/Chat')),
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.chat.root,
    //   component: () => <Redirect to={PATH_DASHBOARD.chat.new} />,
    // },
    // // APP : MAIL
    // // ----------------------------------------------------------------------
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.mail.labels,
    //   component: lazy(() => import('../views/Mail'))
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.mail.root,
    //   component: () => <Redirect to={PATH_DASHBOARD.mail.all} />
    // },

    // // APP : CALENDAR
    // // ----------------------------------------------------------------------
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.calendar,
    //   component: lazy(() => import('../views/Calendar'))
    // },

    // // FOUNDATIONS
    // // ----------------------------------------------------------------------
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.foundations.colors,
    //   component: lazy(() =>
    //     import('../views/uikit-foundations/FoundationColors')
    //   )
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.foundations.typography,
    //   component: lazy(() =>
    //     import('../views/uikit-foundations/FoundationTypography')
    //   )
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.foundations.shadows,
    //   component: lazy(() =>
    //     import('../views/uikit-foundations/FoundationShadows')
    //   )
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.foundations.grid,
    //   component: lazy(() => import('../views/uikit-foundations/FoundationGrid'))
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.foundations.icons,
    //   component: lazy(() => import('../views/uikit-foundations/icon'))
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.foundations.root,
    //   component: () => <Redirect to={PATH_DASHBOARD.foundations.colors} />
    // },

    // // COMPONENTS
    // // ----------------------------------------------------------------------
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.components.accordion,
    //   component: lazy(() => import('../views/uikit-components/accordion'))
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.components.alert,
    //   component: lazy(() => import('../views/uikit-components/Alert'))
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.components.autocomplete,
    //   component: lazy(() => import('../views/uikit-components/autocomplete'))
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.components.avatar,
    //   component: lazy(() => import('../views/uikit-components/Avatar'))
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.components.badge,
    //   component: lazy(() => import('../views/uikit-components/Badge'))
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.components.breadcrumbs,
    //   component: lazy(() => import('../views/uikit-components/Breadcrumb'))
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.components.buttons,
    //   component: lazy(() => import('../views/uikit-components/buttons'))
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.components.chip,
    //   component: lazy(() => import('../views/uikit-components/chips'))
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.components.dialog,
    //   component: lazy(() => import('../views/uikit-components/dialog'))
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.components.textfield,
    //   component: lazy(() => import('../views/uikit-components/textfield'))
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.components.label,
    //   component: lazy(() => import('../views/uikit-components/Label'))
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.components.lists,
    //   component: lazy(() => import('../views/uikit-components/Lists'))
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.components.menu,
    //   component: lazy(() => import('../views/uikit-components/Menus'))
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.components.pagination,
    //   component: lazy(() => import('../views/uikit-components/Pagination'))
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.components.pickers,
    //   component: lazy(() => import('../views/uikit-components/pickers'))
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.components.popover,
    //   component: lazy(() => import('../views/uikit-components/Popover'))
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.components.progress,
    //   component: lazy(() => import('../views/uikit-components/progress'))
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.components.rating,
    //   component: lazy(() => import('../views/uikit-components/Rating'))
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.components.selectionControls,
    //   component: lazy(() =>
    //     import('../views/uikit-components/selection-controls')
    //   )
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.components.snackbar,
    //   component: lazy(() => import('../views/uikit-components/Snackbar'))
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.components.slider,
    //   component: lazy(() => import('../views/uikit-components/Slider'))
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.components.stepper,
    //   component: lazy(() => import('../views/uikit-components/stepper'))
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.components.tabs,
    //   component: lazy(() => import('../views/uikit-components/Tabs'))
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.components.table,
    //   component: lazy(() => import('../views/uikit-components/table'))
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.components.timeline,
    //   component: lazy(() => import('../views/uikit-components/Timeline'))
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.components.tooltip,
    //   component: lazy(() => import('../views/uikit-components/Tooltip'))
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.components.transferList,
    //   component: lazy(() => import('../views/uikit-components/transfer-list'))
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.components.treeView,
    //   component: lazy(() => import('../views/uikit-components/TreeView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.components.root,
    //   component: () => <Redirect to={PATH_DASHBOARD.components.accordion} />
    // },

    // // EXTRA COMPONENTS
    // // ----------------------------------------------------------------------
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.components.chart,
    //   component: lazy(() => import('../views/uikit-extra-components/Charts'))
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.components.map,
    //   component: lazy(() => import('../views/uikit-extra-components/Map'))
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.components.editor,
    //   component: lazy(() => import('../views/uikit-extra-components/Editor'))
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.components.copyToClipboard,
    //   component: lazy(() =>
    //     import('../views/uikit-extra-components/CopyToClipboard')
    //   )
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.components.upload,
    //   component: lazy(() => import('../views/uikit-extra-components/Upload'))
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.components.carousel,
    //   component: lazy(() => import('../views/uikit-extra-components/Carousel'))
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.components.multiLanguage,
    //   component: lazy(() =>
    //     import('../views/uikit-extra-components/MultiLanguage')
    //   )
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.components.animate,
    //   component: lazy(() => import('../views/uikit-extra-components/animate'))
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.components.extraComponents,
    //   component: () => <Redirect to={PATH_DASHBOARD.components.chart} />
    // }

    // ----------------------------------------------------------------------

    {
      component: () => <Redirect to="/404" />,
    },
  ],
};

export default DashboardRoutes;
