import firebase from 'firebase/app';
import 'firebase/database';
// form
import 'firebase/auth';

const sessionsBaseUrl = 'sessions_branch';
const messagesBaseUrl = 'messages_chat_branch';
const phonesBaseUrl = 'phones_sessions_branch';
const contactsBaseUrl = 'contacts_sessions_branch';

export async function getSessionsByBranchId(branchId) {
  return firebase
    .database()
    .ref(`/${sessionsBaseUrl}/${branchId}`)
    .get()
    .then((snapshot) => (snapshot.exists() ? snapshot.val() : []))
    .catch((error) => {
      console.error(error);
    });
}

export async function createSession(branchId, data) {
  await firebase
    .database()
    .ref(`/${sessionsBaseUrl}/${branchId}`)
    .set(data)
    .catch((error) => {
      console.error(error);
    });
}

export async function getMessageByChatBySession(branchId, chatId, sessionId) {
  return firebase
    .database()
    .ref(`/${messagesBaseUrl}/${branchId}/${chatId}/${sessionId}`)
    .get()
    .then((snapshot) => (snapshot.exists() ? snapshot.val() : []))
    .catch((error) => {
      console.error(error);
    });
}

export const listenForChangesInConversation = async (branchId, sessionId, callback) => {
  const myTable = firebase.database().ref(`/${messagesBaseUrl}/${branchId}/${sessionId}`);
  myTable.on('child_changed', async (snapshot) => {
    // const data = await getInvoiceReportApiHaciendaById(id, branchID);
    const data = snapshot.val();
    console.log('data Realtime child_changed', data);
    callback(data);
  });
};

export const unsubscribeConversation = async (branchId, sessionId, callback) => {
  const myTable = firebase.database().ref(`/${messagesBaseUrl}/${branchId}/${sessionId}`);

  // Para desubscribirte del evento, utiliza la referencia al callback
  myTable.off('child_changed', callback);
};

/* Ya se guarda en firebase cuando se toca un chat pero se guardan los msj que ya traían, 
lo ideal sería que junte los que están en firebase con los nuevos que trae con el allMessages del backend,
 no se tienen que repetir e ir en orden.
*/
export async function setMessagesByChatBySession(branchId, data, chatId, sessionId) {
  await firebase
    .database()
    .ref(`/${messagesBaseUrl}/${branchId}/${sessionId}/${chatId.replace('@', '').replace('.', '')}`)
    .set(data)
    .catch((error) => {
      console.error(error);
    });
}

export async function getConversationsBySession(branchId, sessionId) {
  return firebase
    .database()
    .ref(`/${messagesBaseUrl}/${branchId}/${sessionId}`)
    .get()
    .then((snapshot) => (snapshot.exists() ? snapshot.val() : []))
    .catch((error) => {
      console.error(error);
    });
}

export async function setConversationsBySession(branchId, sessionId, data) {
  data.forEach(async (conversation) => {
    await firebase
      .database()
      .ref(`/${messagesBaseUrl}/${branchId}/${sessionId}/${conversation.to.replace('@', '').replace('.', '')}`)
      .set({ conversation })
      .catch((error) => {
        console.error(error);
      });
  });
}

export async function setFirebaseConversationsBySession(branchId, sessionId, data) {
  data.forEach(async (conversation) => {
    console.log('conversation', conversation);
    console.log('conversation to', conversation?.to);
    await firebase
      .database()
      .collection(messagesBaseUrl)
      .doc(branchId)
      .collection(sessionId)
      .doc(conversation.conversation.to.replace('@', '').replace('.', ''))
      .set({ conversation })
      .catch((error) => {
        console.error(error);
      });
  });
}

async function deleteAllDocumentsForACollection(query) {
  const batch = firebase.firestore().batch();
  const snapshot = await query.get();
  snapshot.docs.forEach((doc) => batch.delete(doc.ref));
  await batch.commit();
}

const deleteAllChatsBySession = async (branchId, sessionId) => {
  const collectionRef = firebase.firestore().collection(messagesBaseUrl).doc(branchId).collection(sessionId);

  const query = collectionRef;

  // Si tienes una gran cantidad de documentos, puedes paginar la eliminación
  // para no exceder el límite de eliminación por lotes de Firestore.
  // Puedes utilizar el método limit() y startAfter() para esto.
  await deleteAllDocumentsForACollection(query);

  // await collectionRef?.delete();
};

export async function setSessionByPhone(branchId, data, phoneNumber, sessionId) {
  await firebase
    .firestore()
    .collection(phonesBaseUrl)
    .doc(branchId)
    .collection(phoneNumber)
    .doc(sessionId)
    .set(data)
    .catch((error) => {
      console.error(error);
    });
}

export async function getSessionByPhone(branchId, phoneNumber) {
  return firebase
    .firestore()
    .collection(phonesBaseUrl)
    .doc(branchId)
    .collection(phoneNumber)
    .get()
    .then((snap) => {
      console.log('snap', snap);
      if (snap.docs.length > 0) {
        return snap.docs[0].data().id;
      }
      return '';
    })
    .catch((error) => {
      console.error(error);
    });
}

export async function getPhonesByBranch(branchId) {
  return firebase
    .firestore()
    .collection(phonesBaseUrl)
    .doc(branchId)
    .get()
    .then((snap) => {
      const data = snap.data();
      console.log('snap', snap);
      console.log('data', data);
    })
    .catch((error) => {
      console.error(error);
    });
}

const deleteSessionByPhone = async (branchId, phoneNumber, sessionId) => {
  const docRef = firebase.firestore().collection(phonesBaseUrl).doc(branchId).collection(phoneNumber).doc(sessionId);

  await docRef.delete();
};

export const setChatsToNewSession = async (branchId, oldSessionId, newSessionId, phoneNumber) => {
  // obtener chats
  const chats = await getConversationsBySession(branchId, oldSessionId);
  console.log('chats firebase', chats);

  console.log('newSessionId', newSessionId);
  // setear chats a la nueva sesión
  await setFirebaseConversationsBySession(branchId, newSessionId, chats);

  console.log('seteado');
  // eliminar chats sesión vieja
  await deleteAllChatsBySession(branchId, oldSessionId);
  console.log('eliminado');

  // eliminar apuntador de phone
  await deleteSessionByPhone(branchId, phoneNumber, oldSessionId);
};

export async function getContactsBySessionByBranch(branchId, sessionId) {
  return firebase
    .database()
    .ref(`/${contactsBaseUrl}/${branchId}/${sessionId}`)
    .get()
    .then((snapshot) => (snapshot.exists() ? snapshot.val() : {}))
    .catch((error) => {
      console.error(error);
    });
}

export async function setContactsBySessionByBranch(branchId, sessionId, data) {
  await firebase
    .database()
    .ref(`/${contactsBaseUrl}/${branchId}/${sessionId}`)
    .set(data)
    .catch((error) => {
      console.error(error);
    });
}

export const listenForChangesInContacts = async (branchId, sessionId, callback) => {
  const myTable = firebase.database().ref(`/${contactsBaseUrl}/${branchId}/${sessionId}`);
  myTable.on('child_changed', async (snapshot) => {
    // const data = await getInvoiceReportApiHaciendaById(id, branchID);
    const data = snapshot.val();
    console.log('data Realtime child_changed', data);
    callback(data);
  });
};
