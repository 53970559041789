const jsonsToPrettyString = (mList) => {
  const newList = [];
  if (mList === '' || typeof mList === 'object') {
    return '';
  }
  mList.forEach((element) => {
    let el = JSON.stringify(element);
    el = el.replaceAll('"', '');
    el = el.replaceAll('{', '');
    el = el.replaceAll('}', '');
    el = el.replaceAll(',', ',   ');
    newList.push(el);
  });
  return newList;
};

const getProp = (obj, key) => key.split('.').reduce((o, x) => (typeof o === 'undefined' || o === null ? o : o[x]), obj);

const getFieldsModified = (oldValue, updatedValue) => {
  const uniqueValue = [];

  if (oldValue === undefined || oldValue?.length === 0) {
    Object.keys(updatedValue).forEach((key) => {
      if (typeof updatedValue[key] !== 'object') {
        uniqueValue.push({ field: key, updatedValue: updatedValue[key] });
      }
    });
  } else {
    Object.keys(updatedValue).forEach((key) => {
      if (oldValue[key] !== updatedValue[key] && typeof updatedValue[key] !== 'object') {
        uniqueValue.push({ field: key, oldValue: oldValue[key] ?? '', updatedValue: updatedValue[key] });
      } else if (typeof updatedValue[key] === 'object') {
        if (JSON.stringify(oldValue[key]) !== JSON.stringify(updatedValue[key])) {
          console.log('oldValue', oldValue[key]);
          console.log('oldValue', updatedValue[key]);
          const oldValueChild = jsonsToPrettyString(oldValue[key] ?? '');
          const updatedValueChild = jsonsToPrettyString(updatedValue[key]);
          uniqueValue.push({ field: key, oldValue: oldValueChild, updatedValue: updatedValueChild });
          // uniqueValue.push({ field: key, oldValue: oldValue[key], updatedValue: updatedValue[key] });
        }
      }
    });
  }
  return uniqueValue;
};

export default getFieldsModified;
