import firebase from 'firebase/app';
import { initialState, setCurrentBoard } from '../redux/slices/project';
import { dispatch } from '../redux/store';
import { createLog } from '../redux/slices/log';

const noteBaseUrl = 'note_branch';

const getCardBaseUrl = (branchId, cardId, familyId) => `${noteBaseUrl}/${branchId}/${familyId}/cards/${cardId}`;

const getColumnBaseUrl = (branchId, columnId, familyId) => `${noteBaseUrl}/${branchId}/${familyId}/columns/${columnId}`;

export async function getCurrentNote(branchId, cardId) {
  const rootRef = await firebase
    .database()
    .ref(`/${noteBaseUrl}/${branchId}/${cardId}`)
    .once('value')
    .then((snap) => snap.val());
  return rootRef;
}

export const createNoteConversation = async (branchId, board, cardId) => {
  const boardRef = firebase.database().ref(`/${noteBaseUrl}/${branchId}/${cardId}`);
  // const updates = {};
  // updates[`/${projectBaseUrl}/${branchId}/${boardId}`] = { id: boardId, ...board };
  // await firebase.database().ref().update(updates);
  // console.log('create board', boardId);
  const newBoard = {
    ...board,
    id: cardId,
    branchId,
  };
  await boardRef.set(newBoard);
  return newBoard;
};
