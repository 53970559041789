export const CURRENCY_OPTIONS = [
  { name: 'USD-Dollar', value: 'dolar' },
  { name: 'Euro', value: 'euro' },
  { name: 'Colon', value: 'colon' },
  { name: 'MXN', value: 'Peso Mexicano' },
  { name: 'QTZ', value: 'Quetzal' },
];

export const PAYMENT_TERMS_OPTIONS = [
  {
    name: 'Cash Payment',
    value: 'cashPayment',
  },
  {
    name: 'Fifteen Days',
    value: '15days',
  },
  {
    name: 'Thirty Days',
    value: '30days',
  },
  {
    name: 'Forty Five Days',
    value: '45days',
  },
  {
    name: 'Sixty Days',
    value: '60days',
  },
];

export const SALES_CONDITIONS = [
  {
    name: 'Contado',
    value: '01',
  },
  {
    name: 'Credito',
    value: '02',
  },
  {
    name: 'Consignación',
    value: '03',
  },
  {
    name: 'Apartado',
    value: '04',
  },
  {
    name: 'Arrendamiento con opción de compra',
    value: '05',
  },
  {
    name: 'Arrendamiento en función financiera',
    value: '06',
  },
  {
    name: 'Cobro a favor de un tercero',
    value: '07',
  },
  {
    name: 'Servicios prestados al Estado a crédito',
    value: '08',
  },
  {
    name: 'Pago del servicios prestado al Estado',
    value: '09',
  },
  {
    name: 'Otros',
    value: '99',
  },
];

export const PAYMENT_METHODS = [
  {
    name: 'Efectivo',
    value: '01',
  },
  {
    name: 'Tarjeta',
    value: '02',
  },
  {
    name: 'Cheque',
    value: '03',
  },
  {
    name: 'Transferencia - depósito bancario',
    value: '04',
  },
  {
    name: 'Recaudado por terceros',
    value: '05',
  },
  {
    name: 'Otros',
    value: '99',
  },
];

export const RATE_TAXES_LIST = [
  {
    id: '01',
    label: 'Tarifa 0% (Exento)',
    value: 0,
  },
  {
    id: '02',
    label: 'Tarifa reducida 1%',
    value: 1,
  },
  {
    id: '03',
    label: 'Tarifa reducida 2%',
    value: 2,
  },
  {
    id: '04',
    label: 'Tarifa reducida 4%',
    value: 4,
  },
  {
    id: '05',
    label: 'Transitorio 0%',
    value: 0,
  },
  {
    id: '06',
    label: 'Transitorio 4%',
    value: 4,
  },
  {
    id: '07',
    label: 'Transitorio 8%',
    value: 8,
  },
  {
    id: '08',
    label: 'Tarifa general 13%',
    value: 13,
  },
];

export const GREETING_OPTIONS = [
  {
    name: 'Mr',
    value: 'Mr',
  },
  {
    name: 'Mrs',
    value: 'Mrs',
  },
  {
    name: 'Dr',
    value: 'Dr',
  },
];

export const SOCIAL_OPTIONS = [
  {
    name: 'LinkedIn',
    value: 'LinkedIn',
  },
  {
    name: 'Facebook',
    value: 'Facebook',
  },
  {
    name: 'Instagram',
    value: 'Instagram',
  },
  {
    name: 'Google',
    value: 'Google',
  },
  {
    name: 'Twitter',
    value: 'Twitter',
  },
  {
    name: 'Whatsapp',
    value: 'Whatsapp',
  },
];

export const IDENTIFICATION_TYPE_OPTIONS = [
  {
    name: 'Physical Identity Card',
    value: 'physicalIdentityCard',
  },
  {
    name: 'Legal Identity Card',
    value: 'legalIdentityCard',
  },
  {
    name: 'Without ID',
    value: 'noId',
  },
];

export const TAX_TYPES = [
  {
    code: '01',
    value: '01',
    label: 'Impuesto al Valor Agregado',
  },
  {
    code: '02',
    value: '02',
    label: 'Impuesto Selectivo de Consumo',
  },
  {
    code: '03',
    value: '03',
    label: 'Impuesto Único a los Combustibles',
  },
  {
    code: '04',
    value: '04',
    label: 'Impuesto específico de Bebidas Alcohólicas',
  },
  {
    code: '05',
    value: '05',
    label: 'Impuesto Específico sobre las bebidas envasadas sin contenido alcohólico y jabones de tocador',
  },
  {
    code: '06',
    value: '06',
    label: 'Impuesto a los Productos de Tabaco',
  },
  {
    code: '07',
    value: '07',
    label: 'IVA (cálculo especial)',
  },
  {
    code: '08',
    value: '08',
    label: 'IVA Régimen de Bienes Usados (Factor)',
  },
  {
    code: '12',
    value: '12',
    label: 'Impuesto Específico al Cemento',
  },
  {
    code: '99',
    value: '99',
    label: 'otros',
  },
];

export const countries = [
  { code: 'mx', label: 'México', numericCode: '52' },
  { code: 'cr', label: 'Costa Rica', numericCode: '506' },
  { code: 'gt', label: 'Guatemala', numericCode: '502' },
  { code: 'other', label: 'Otros', numericCode: '' },
];

export const STATUS_CRM = [
  { value: 'active', label: 'Activo' },
  { value: 'documentation', label: 'Documentación' },
  { value: 'in_process', label: 'En proceso' },
  { value: 'in_pause', label: 'En pausa' },
  { value: 'canceled', label: 'Cancelado' },
];

export const STATUS_PROJECT = [
  { value: 'active', label: 'Activo' },
  { value: 'documentation', label: 'Documentación' },
  { value: 'in_process', label: 'En proceso' },
  { value: 'in_pause', label: 'En pausa' },
  { value: 'canceled', label: 'Cancelado' },
  { value: 'finished', label: 'Finalizado' },
];

export const PRIORITIZES_CRM = [
  { value: 'low', label: 'Baja' },
  { value: 'medium', label: 'Media' },
  { value: 'hight', label: 'Alta' },
];

export const PRIORITIZES_PROJECT = [
  { value: 'low', label: 'Baja' },
  { value: 'normal', label: 'Normal' },
  { value: 'medium', label: 'Media' },
  { value: 'hight', label: 'Alta' },
];

export const listOfProceduresCR = [
  {
    nameProcedure: 'Trámite Acto General',
    category: 'Acto General',
    detail: '<p>Detalle 1</p>',
    honoraries: '1000',
    taxSaleInfo: '5',
    accountSaleInfo: 'Cuenta de Ventas',
    stamps: [
      {
        stampId: 0,
        stampName: 'Timbre',
        stampPrice: '1000',
        stampPercentage: '1',
      },
    ],
  },
];

export const MODULES = [
  {
    id: 'FDV',
    label: 'Facturas de Venta',
    module: 'Contabilidad - Ventas',
  },
  {
    id: 'CPV',
    label: 'Cotizaciones para Venta',
    module: 'Contabilidad - Ventas',
  },
  {
    id: 'NDC',
    label: 'Notas de Crédito',
    module: 'Contabilidad - Ventas',
  },
  {
    id: 'FRR',
    label: 'Facturas Recurrentes',
    module: 'Contabilidad - Ventas',
  },
  {
    id: 'DIR',
    label: 'Directorio',
    module: 'Administración',
  },
  {
    id: 'ART',
    label: 'Artículos',
    module: 'Administración',
  },
  {
    id: 'PPE',
    label: 'Gastos',
    module: 'Contabilidad - Compras',
  },
  {
    id: 'FDP',
    label: 'Facturas de Proveedor',
    module: 'Contabilidad - Compras',
  },
  {
    id: 'BA',
    label: 'Cuentas Bancarias',
    module: 'Contabilidad - Cuentas',
  },
  {
    id: 'PL',
    label: 'Procesos',
    module: 'Legal',
  },
  {
    id: 'TL',
    label: 'Trámites',
    module: 'Legal - Honorarios',
  },
  {
    id: 'CT',
    label: 'Cotizaciones Legales',
    module: 'Legal - Honorarios',
  },
  {
    id: 'CRM',
    label: 'CRM',
    module: 'Legal',
  },
  {
    id: 'CRMPL',
    label: 'Proyectos',
    module: 'Operaciones',
  },
  {
    id: 'ECM',
    label: 'e-commerce',
    module: 'e-commerce',
  },
  {
    id: 'NEWS',
    label: 'Noticias',
    module: 'Perfil',
  },
];

export const BANKS = [
  { id: 'BAC', label: 'BAC San Jose' },
  { id: 'BNCR', label: 'Banco Nacional' },
  { id: 'BCR', label: 'Banco de Costa Rica' },
  { id: 'BLF', label: 'Banco LaFise' },
  { id: 'BDV', label: 'Banco Davivienda' },
  { id: 'BPA', label: 'Banco Promerica' },
  { id: 'BP', label: 'Banco Popular' }, // no estaba en la lista
];

export const FILTER_OPTIONS_MASSIVE_UPLOAD = [
  { id: 'all', label: 'Todas' },
  { id: 'success', label: 'Correctos' },
  { id: 'errors', label: 'Errores' },
];
