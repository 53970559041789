const jsonsToPrettyString = (mList) => {
    const newList = [];
    if (mList === '' || typeof mList === 'object') {
      return '';
    }
    mList.forEach((element) => {
      let el = JSON.stringify(element);
      el = el.replaceAll('"', '');
      el = el.replaceAll('{', '');
      el = el.replaceAll('}', '');
      el = el.replaceAll(',', ',   ');
      newList.push(el);
    });
    return newList;
  };
  
  const getFieldsModifiedContact = (oldValue, updatedValue) => {
    const uniqueValue = [];
  try {
    if (oldValue === undefined || oldValue.length === 0) {
      Object.keys(updatedValue).forEach((key) => {
        if (typeof updatedValue[key] !== 'object') {
          uniqueValue.push({ field: key, updatedValue: updatedValue[key] });
        }
      });
    } else {
      Object.keys(updatedValue).forEach((key) => {
        if (oldValue[key] !== updatedValue[key] && typeof updatedValue[key] !== 'object') {
          uniqueValue.push({ field: key, oldValue: oldValue[key] ?? '', updatedValue: updatedValue[key] ?? '' });
        } else if (typeof updatedValue[key] === 'object') {
            
          if (oldValue[key]?.length !== updatedValue[key]?.length) {
            
            const listItemsOld = oldValue[key]?.map((d) => d?.id) || '';
            const listItemsUpdate = updatedValue[key]?.map((d) => d?.id) || '';
            console.log('listItemsOld',listItemsOld );
            console.log('listItemsUpdate',listItemsUpdate);
  
            uniqueValue.push({ field: key, oldValue: listItemsOld || '', updatedValue: listItemsUpdate || '' });
            // uniqueValue.push({ field: key, oldValue: oldValue[key], updatedValue: updatedValue[key] });
          }
        }
      });
    }
  } catch (err) {
    console.error(err);
    return false;
  }
    return uniqueValue;
  };
  
  export default getFieldsModifiedContact;
  