import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/database';
import 'firebase/firestore';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isLoaded, useFirestore } from 'react-redux-firebase';
import { firebaseConfig } from '../config';
import { login, logout, register } from '../redux/slices/user';

useAuth.propTypes = {
  method: PropTypes.oneOf(['jwt', 'firebase']),
};

firebase.initializeApp(firebaseConfig);

if (process.env.REACT_APP_ENVIRONMENT === 'local') {
  console.log('Running in local environment');
  firebase.functions().useFunctionsEmulator('http://localhost:5001');
  firebase.auth().useEmulator('http://localhost:9099', { disableWarnings: true });
  firebase.database().useEmulator('localhost', 9000);
}

export default function useAuth() {
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const { userProfile, isLoading, isAuthenticated } = useSelector((state) => state.user);
  return {
    method: 'jwt',
    userProfile,
    isLoading,
    isAuthenticated,

    login: ({ email, password }) =>
      firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .then(() =>
          firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then((userAuth) => {
              const { user } = userAuth;
              enqueueSnackbar('Login success', {
                variant: 'success',
              });
              dispatch(login(user));
            })
            .catch((e) =>
              enqueueSnackbar(e.message, {
                variant: 'error',
              })
            )
        ),
    register: (user) => firebase.auth().createUserWithEmailAndPassword(user.email, user.password),

    logout: () => dispatch(logout()),

    resetPassword: (email) => {
      firebase.auth().sendPasswordResetEmail(email);
    },

    updateProfile: () => {},
  };
  // return {
  //   method: 'firebase',
  //   user: {
  //     email: auth.email || '',
  //     photoURL: auth.photoURL || profile.photoURL || '',
  //     phoneNumber: auth.phoneNumber || profile.phoneNumber || '',
  //     country: profile.country || '',
  //     address: profile.address || '',
  //     state: profile.state || '',
  //     city: profile.city || '',
  //     zipCode: profile.zipCode || '',
  //     about: profile.about || '',
  //     role: profile.role || '',
  //     isPublic: profile.isPublic || false
  //   },
  //   isLoading: !isLoaded(auth),
  //   isAuthenticated: !isEmpty(auth),

  //   login: ({ email, password }) =>
  //     firebase.auth
  //       .createUserWithEmailAndPassword(email, password)
  //       .then(() => {})
  //       .catch((e) => console.log(e.code)),
  //   loginWithGoogle: () =>
  //     firebase.login({ provider: 'google', type: 'popup' }),

  //   loginWithFaceBook: () =>
  //     firebase.login({ provider: 'facebook', type: 'popup' }),

  //   loginWithTwitter: () =>
  //     firebase.login({ provider: 'twitter', type: 'popup' }),

  //   register: ({ email, password }) =>
  //     firebase
  //       .auth()
  //       .createUserWithEmailAndPassword(email, password)
  //       .then((res) => {
  //         firestore.collection('users').doc(res.user.uid).set({
  //           uid: res.user.uid,
  //           email
  //         });
  //       }),

  //   logout: () => firebase.logout(),

  //   resetPassword: (email) => firebase.resetPassword(email),

  //   updateProfile: (values) =>
  //     firebase
  //       .auth()
  //       .currentUser.updateProfile(values)
  //       .then((res) => {
  //         firestore
  //           .collection('users')
  //           .doc(res.id)
  //           .set(values, { merge: true });
  //       })
  // };
}
