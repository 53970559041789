import { createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import objFromArray from '../../utils/objFromArray';

const initialState = {
  isLoading: false,
  error: false,
  emails: { byId: {}, allIds: [] },
  labels: []
};

const slice = createSlice({
  name: 'email',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getLabelsSuccess(state, action) {
      state.isLoading = false;
      state.labels = action.payload;
    },

    getMailsSuccess(state, action) {
      const emails = action.payload;

      state.isLoading = false;
      state.emails.byId = objFromArray(emails);
      state.emails.allIds = Object.keys(state.emails.byId);
    },

    getMailSuccess(state, action) {
      const email = action.payload;

      state.emails.byId[email.id] = email;
      if (!state.emails.allIds.includes(email.id)) {
        state.emails.allIds.push(email.id);
      }
    }
  }
});

export default slice.reducer;

export function getLabels() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/email/labels');
      dispatch(slice.actions.getLabelsSuccess(response.data.labels));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMails(params, word) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/email/mails', { params });
      if (word) {
        const FilteredEmail = response.data.mails.filter(
          (data) =>
            data.subject.includes(word) ||
            data.message.includes(word) ||
            data.from.name.includes(word) ||
            data.from.email.includes(word)
        );
        dispatch(slice.actions.getMailsSuccess(FilteredEmail));
      } else {
        dispatch(slice.actions.getMailsSuccess(response.data.mails));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMail(emailId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/email/mail', {
        params: { emailId }
      });
      dispatch(slice.actions.getMailSuccess(response.data.email));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
