import firebase from 'firebase/app';
import { setCurrentBoard } from '../redux/slices/kanban';
import { dispatch } from '../redux/store';
import { createLog } from '../redux/slices/log';
import { initialState } from '../redux/slices/queue';
import { moveCardIntoColumn } from './WaitingRoomService';

const queuesBaseUrl = 'queues_branch';
const messagesBaseUrl = 'messages_chat_company';

const getCardBaseUrl = (branchId, currentQueueId, cardId) =>
  `${queuesBaseUrl}/${branchId}/${currentQueueId}/cards/${cardId}`;

const getColumnBaseUrl = (branchId, currentQueueId, columnId) =>
  `${queuesBaseUrl}/${branchId}/${currentQueueId}/columns/${columnId}`;

export async function getCurrentBoard(branchId, currentQueueId) {
  const rootRef = await firebase
    .database()
    .ref(`/${queuesBaseUrl}/${branchId}/${currentQueueId}`)
    .once('value')
    .then((snap) => snap.val());
  return rootRef;
}

export const createBoard = async (branchId, board, currentQueueId) => {
  const boardRef = firebase.database().ref(`/${queuesBaseUrl}/${branchId}/${currentQueueId}`);
  // const updates = {};
  // updates[`/${waitingRoomBaseUrl}/${branchId}/${boardId}`] = { id: boardId, ...board };
  // await firebase.database().ref().update(updates);
  // console.log('create board', boardId);
  const newBoard = {
    ...board,
    id: branchId,
    currentQueueId,
  };
  await boardRef.set(newBoard);
  return newBoard;
};

// export const updateBoard = async (branchId, board) => {
//   const updates = {};
//   updates[`/${waitingRoomBaseUrl}/${branchId}/${board.id}`] = board;
//   await firebase.database().ref().update(updates);
//   console.log('update board', board.id);
//   return board.id;
// };

export const createCard = async (branchId, currentBoard, card, columnId, currentQueueId) => {
  // state.currentBoard.cards[card.id] = card;
  // const boardId = currentBoard.id;
  const cardId = firebase.database().ref(`/${queuesBaseUrl}/${branchId}/${currentQueueId}`).push().key;
  const updates = {};
  const cardBaseUrl = getCardBaseUrl(branchId, currentQueueId, cardId);
  updates[`/${cardBaseUrl}`] = { ...card, id: cardId, columnId };

  // state.currentBoard.columns[columnId].cardIds.push(card.id);
  const cardIds = [...(currentBoard.columns[columnId]?.cardIds || []), cardId];

  // const cardIds = currentBoard.columns[columnId]?.cardIds || [];
  // console.log('cardIds',cardIds)
  // cardIds.push(cardId);

  const columnBaseUrl = getColumnBaseUrl(branchId, currentQueueId, columnId);
  updates[`/${columnBaseUrl}/cardIds`] = cardIds;

  await firebase.database().ref().update(updates);

  await createLog('project', 'create', `${cardId}`, [], card);
  return cardId;
};

export const updateCard = async (branchId, currentQueueId, card, oldCard = []) => {
  const log = await createLog('project', 'update', `${card.id}`, oldCard, card);

  const currentBoard = await getCurrentBoard(branchId, currentQueueId);
  // console.log('currentBoard', currentBoard);

  // const cardBaseUrl = getCardBaseUrl(branchId, card.id);
  // updates[`/${cardBaseUrl}`] = card;
  // `${waitingRoomBaseUrl}/${branchId}/cards/${cardId}`

  const newBoard = {
    ...currentBoard,
    cardLogs: { ...currentBoard?.cardLogs, [log]: true },
    cards: {
      ...currentBoard.cards,
      [card.id]: card,
    },
  };

  const updates = {};
  updates[`/${queuesBaseUrl}/${branchId}/${currentQueueId}`] = newBoard;

  await firebase.database().ref().update(updates);
  dispatch(setCurrentBoard(newBoard));
};

export const deleteCard = async (branchId, currentBoard, cardId, columnId) => {
  // const boardId = currentBoard.id;
  // state.currentBoard.columns[columnId].cardIds = state.currentBoard.columns[columnId].cardIds.filter(
  //   (id) => id !== cardId
  // );
  const updates = {};
  const columnBaseUrl = getColumnBaseUrl(branchId, currentBoard.currentQueueId, columnId);
  const { cardIds } = currentBoard.columns[columnId];
  const newList = cardIds.filter((id) => id !== cardId);

  updates[`/${columnBaseUrl}/cardIds`] = newList;
  await firebase.database().ref().update(updates);

  // state.currentBoard.cards = omit(state.currentBoard.cards, [cardId]);
  const cardBaseUrl = getCardBaseUrl(branchId, currentBoard.currentQueueId, cardId);
  const rootRef = firebase.database().ref(`/${cardBaseUrl}`);
  await rootRef.remove();
};

// newColumnOrder
export const changeColumnOrder = async (branchId, currentBoard, newColumnOrder) => {
  const boardUrl = `${queuesBaseUrl}/${branchId}/${currentBoard.currentQueueId}/columnOrder`;
  const updates = {};
  updates[`/${boardUrl}`] = newColumnOrder;
  await firebase.database().ref().update(updates);
};

// persistCard
export const changeColumns = async (branchId, currentBoard, columns, start, finish, card) => {
  await createLog('project', 'moved', `${card.id}`, { progress: start.name }, { progress: finish.name });

  const boardUrl = `${queuesBaseUrl}/${branchId}/${currentBoard.currentQueueId}/columns`;
  const cardUrl = `${queuesBaseUrl}/${branchId}/${currentBoard.currentQueueId}/cards/${card.id}`;

  // console.log('boardUrl', boardUrl);

  const updates = {};
  updates[`/${boardUrl}`] = columns;
  updates[`/${cardUrl}/columnId`] = finish.id;
  await firebase.database().ref().update(updates);
};

export const getCards = async (branchId, currentBoard) => {
  const data = await firebase
    .database()
    .ref(`/${queuesBaseUrl}/${branchId}/${currentBoard.currentQueueId}/cards`)
    .once('value')
    .then((snap) => snap.val());
  return data;
};

export const getCardById = async (branchId, currentBoard, cardId) => {
  const data = await firebase
    .database()
    .ref(`/${queuesBaseUrl}/${branchId}/${currentBoard.currentQueueId}/cards/${cardId}`)
    .once('value')
    .then((snap) => snap.val());
  return data;
};

// export const addDocumentToCard = async (branchId, cardId, doc) => {
//   const cardBaseUrl = getCardBaseUrl(branchId, cardId);
//   const currentCard = await getCardById(branchId, cardId);

//   const newCard = {
//     ...currentCard,
//     documents: [...(currentCard?.documents || []), doc],
//   };

//   const updates = {};
//   updates[`/${cardBaseUrl}`] = newCard;
//   await firebase.database().ref().update(updates);
// };

const setCard = (name, element, currentBoard, card) => {
  const { cards } = currentBoard;
  const newBoard = {
    ...currentBoard,
    cards: {
      ...cards,
      [card.id]: {
        ...card,
        [name]: { ...(card[name] || {}), [element.id]: element },
      },
    },
  };
  // console.log('newBoard', newBoard);
  dispatch(setCurrentBoard(newBoard));
};

export const addFileOnCard = async (branchId, cardId, file, currentBoard, card) => {
  const cardBaseUrl = getCardBaseUrl(branchId, currentBoard.currentQueueId, cardId);
  const updates = {};
  updates[`/${cardBaseUrl}/files_path/${file.id}`] = file;
  await firebase.database().ref().update(updates);

  setCard('files_path', file, currentBoard, card);
};

export const getUrlFileOfAnAttachment = async (branchId, cardId, idEncoded) => {
  const cardBaseUrl = getCardBaseUrl(branchId, cardId);

  const rootRef = await firebase
    .database()
    .ref(`/${cardBaseUrl}/files_path/${idEncoded}`)
    .once('value')
    .then((snap) => snap.val());
  return rootRef;
};

export const addDefaultQueue = async (branchId, queue) => {
  const updates = {};
  updates[`${queuesBaseUrl}/${branchId}/${queue.id}`] = queue;
  await firebase.database().ref().update(updates);
};

export const getQueuesByBranch = async (branchId) => {
  const rootRef = await firebase
    .database()
    .ref(`/${queuesBaseUrl}/${branchId}`)
    .once('value')
    .then((snap) => snap.val() || {});

  const idDefaultQueue = '-A-default';
  if (!rootRef[idDefaultQueue]) {
    // console.log('Crear default');
    const queues = {
      [idDefaultQueue]: {
        columns: initialState.currentBoard.columns,
        columnOrder: initialState.currentBoard.columnOrder,
        id: idDefaultQueue,
        name: 'Default',
      },
      ...rootRef,
    };

    await addDefaultQueue(branchId, queues[idDefaultQueue]);
    return queues;
  }
  return rootRef;
};

export const getQueueById = async (branchId, id) => {
  const rootRef = await firebase
    .database()
    .ref(`/${queuesBaseUrl}/${branchId}/${id}`)
    .once('value')
    .then((snap) => snap.val() || {});
  return rootRef;
};

export const createNewQueue = async (branchId, queue) => {
  const rootRef = firebase.database().ref('/');
  const queues = rootRef.child(`${queuesBaseUrl}/${branchId}`);
  const newQueue = await queues.push(queue);

  // add id
  const updates = {};
  updates[`${queuesBaseUrl}/${branchId}/${newQueue.key}/id`] = newQueue.key;
  await firebase.database().ref().update(updates);

  return { ...queue, id: newQueue.key };
};

export const createQueueColumn = async (branchId, currentBoard, column) => {
  const queueBaseUrl = `/${queuesBaseUrl}/${branchId}/${currentBoard.id}`;
  // columnId
  const newColumn = await firebase.database().ref(`${queueBaseUrl}/columns`).push(column);

  // add in column order
  const updates = {};

  const newColumnOrder = [...(currentBoard.columnOrder || []), newColumn.key];
  updates[`${queueBaseUrl}/columnOrder`] = newColumnOrder;
  updates[`${queueBaseUrl}/columns/${newColumn.key}/id`] = newColumn.key;

  await firebase.database().ref().update(updates);

  return newColumn.key;
};

export const updateQueueColumn = async (branchId, currentBoard, column, newName) => {
  const queueBaseUrl = `/${queuesBaseUrl}/${branchId}/${currentBoard.id}`;
  const updates = {};
  updates[`${queueBaseUrl}/columns/${column.id}/name`] = newName;
  firebase.database().ref().update(updates);
};

const clearCurrentQueueIdInCards = async (companyId, sessionId, cardsInColumnToDelete) => {
  const updates = {};
  Object.values(cardsInColumnToDelete || {}).forEach((cardId) => {
    updates[`/${messagesBaseUrl}/${companyId}/${sessionId}/${cardId}/currentQueueId`] = null;
  });
  await firebase.database().ref().update(updates);
};

export const deleteQueueColumn = async (branchId, companyId, sessionId, currentBoard, columnId) => {
  const cardsInColumnToDelete = currentBoard.columns[columnId].cardIds;
  const queueBaseUrl = `/${queuesBaseUrl}/${branchId}/${currentBoard.id}`;
  firebase.database().ref(`${queueBaseUrl}/columns/${columnId}`).remove();

  // delete column at the column order
  const newColumnOrder = currentBoard.columnOrder.filter((el) => el !== columnId);
  const updates = {};
  updates[`${queueBaseUrl}/columnOrder`] = newColumnOrder;

  // filter cards
  // const activeCards = (currentBoard?.cards || []).filter((el) => !cardsInColumnToDelete.includes(el.id));
  // updates[`${queueBaseUrl}/cards`] = activeCards;
  await clearCurrentQueueIdInCards(companyId, sessionId, cardsInColumnToDelete);
  await firebase.database().ref().update(updates);
};

const asignConversationQueue = async (
  branchId,
  whatsappApiBaseUrl,
  newQueue,
  currentConversation,
  sessions,
  moveWaitingRoomCardFromColumn
) => {
  const updates = {};
  const columnId = newQueue.columnOrder[0];

  // add conversation card in queue board
  const newCard = {
    completed: false,
    description: '',
    id: currentConversation.id,
    name: currentConversation.userName,
    sessionId: currentConversation?.sessionId || '', // revisar
    columnId,
  };

  const cardBaseUrl = getCardBaseUrl(branchId, newQueue.id, newCard.id);
  const columnBaseUrl = getColumnBaseUrl(branchId, newQueue.id, columnId);
  updates[cardBaseUrl] = newCard;

  const cardIds = [...(newQueue.columns[columnId].cardIds || []), newCard.id];
  updates[`${columnBaseUrl}/cardIds`] = cardIds;

  // update current queue id
  updates[`${whatsappApiBaseUrl}/currentQueueId`] = newQueue.id;
  await firebase.database().ref().update(updates);

  await moveCardIntoColumn(branchId, newCard, sessions, moveWaitingRoomCardFromColumn);
};

const desvinculateQueueFromConversation = async (branchId, currentConversation) => {
  const queueId = currentConversation.currentQueueId;
  const cardId = currentConversation.id;
  const card = await getCardById(branchId, { currentQueueId: queueId }, cardId);
  console.log('card desvinculateQueueFromConversation', card);

  if (!card) {
    return;
  }
  // delete conversation card in queue board
  const updates = {};
  const cardBaseUrl = getCardBaseUrl(branchId, queueId, cardId);
  updates[`${cardBaseUrl}`] = null;

  // revisar el desvincular, eliminar de la columna id,
  // si no actualizo el estado no cambia currentQueueId
  const oldQueue = await getQueueById(branchId, queueId);
  // console.log('oldQueue', oldQueue);
  const columnId = card?.columnId || 'cero'; // oldQueue.columnOrder[0];
  console.log('columnId', columnId);
  const columnBaseUrl = getColumnBaseUrl(branchId, queueId, columnId);
  // console.log('oldQueue.columns[columnId].cardIds viejo xd', oldQueue.columns[columnId].cardIds);
  // console.log('cardId', cardId);
  const cardIds = (oldQueue.columns[columnId].cardIds || []).filter((el) => el !== cardId);
  // console.log('cardIds nuevo', cardIds);
  updates[`${columnBaseUrl}/cardIds`] = cardIds;

  await firebase.database().ref().update(updates);
};

export const validateConversationHasQueue = async (
  companyId,
  branchId,
  sessionId,
  chatId,
  currentConversation,
  newQueue,
  sessions,
  moveWaitingRoomCardFromColumn
) => {
  const whatsappApiBaseUrl = `/${messagesBaseUrl}/${companyId}/${sessionId}/${chatId}`;
  // console.log('whatsappApiBaseUrl', whatsappApiBaseUrl);

  // console.log('currentConversation.currentQueueId', currentConversation.currentQueueId);
  // si es el mismo, no lo voy a desvincular y asignar a la misma cola
  if (currentConversation.currentQueueId === newQueue.id) {
    return;
  }

  if (currentConversation.currentQueueId) {
    await desvinculateQueueFromConversation(branchId, currentConversation);
  }
  await asignConversationQueue(
    branchId,
    whatsappApiBaseUrl,
    newQueue,
    currentConversation,
    sessions,
    moveWaitingRoomCardFromColumn
  );
};
