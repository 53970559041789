import firebase from 'firebase/app';
import { updateCompany, setCurrentCompany } from '../redux/slices/company';
import { payPalConfig } from '../config';
import { dispatch } from '../redux/store';
import getCompanyToRedux from '../utils/getCompanyToRedux';

const paypalBaseUrl = 'https://api-m.paypal.com';
const subscriptionBaseUrl = 'subscriptions';
const paymentsBaseUrl = 'payments_company';

const getSubscriptionToCompany = (subscription) => {
  const subscriptionToCompany = {
    subscription: {
      planId: subscription.planId,
      planName: subscription.planName,
      id: subscription.payPalSubscriptionId,
      payPalSubscriptionId: subscription.payPalSubscriptionId,
      startedAt: subscription.startedAt,
      finishAt: subscription.finishAt,
      renewal: subscription.renewal,
      isCouponUsed: subscription?.isCouponUsed,
      couponUsed: subscription?.couponUsed,
    },
  };

  return subscriptionToCompany;
};

export const modifyCompany = (subscription, company) => {
  const companyWithSubscription = {
    ...company,
    ...subscription,
  };
  // console.log('companyWithSubscription', companyWithSubscription);
  return dispatch(updateCompany(companyWithSubscription));
};

const addPaymentHistory = async (subscription, companyId) => {
  const rootRef = firebase.database().ref('/');
  const paymentsRef = rootRef.child(`${paymentsBaseUrl}/${companyId}`);
  const paymentResult = await paymentsRef.push(subscription);
  // console.log('paymentResult', paymentResult);
  return paymentResult;
};

// eslint-disable-next-line consistent-return
export const createNewSubscription = async (subscription, company) => {
  try {
    await addPaymentHistory(subscription, company.id);

    // const rootRef = firebase.database().ref('/');
    // console.log('rootRef', rootRef);
    // const subscriptionsRef = rootRef.child(`${subscriptionBaseUrl}/${subscription.payPalSubscriptionId}`);
    // console.log('subscriptionsRef', subscriptionsRef);

    const updates = {};
    updates[`/${subscriptionBaseUrl}/${subscription.payPalSubscriptionId}`] = subscription;
    const subscriptionResult = await firebase.database().ref().update(updates);

    // // const subscriptionResult = await subscriptionsRef.push(subscription);

    // console.log('subscriptionResult', subscriptionResult);

    const subscriptionToCompany = getSubscriptionToCompany(subscription);

    // console.log('subscriptionToCompany', subscriptionToCompany);
    modifyCompany(subscriptionToCompany, company);
    // console.log('Compañía modificada');
    // console.log('nuevo', { ...company, subscription: subscriptionToCompany.subscription });

    const companyToRedux = getCompanyToRedux({
      ...company,
      id: company.id,
      name: company.companyName,
    });

    dispatch(
      setCurrentCompany({
        ...companyToRedux,
        subscription: { ...subscriptionToCompany.subscription },
      })
    );

    return subscriptionResult;
  } catch (error) {
    console.error(error);
  }
};

export const createNewPaymentOfASubsctiption = async (subscription, company) => {
  await addPaymentHistory(subscription, company.id);
  const subscriptionToCompany = getSubscriptionToCompany(subscription);
  modifyCompany(subscriptionToCompany, company);
  updateSubscription(subscription, subscription.payPalSubscriptionId);
};

export const updateSubscription = async (subscription, subscriptionId) => {
  // const currentProcedure = await getProcedureByCompany(procedure.id, companyId);

  // const log = await createLog('procedure', 'edit', procedure.id, currentProcedure, procedure);
  // const newProcedure = {
  //   ...procedure,
  //   log: { ...procedure.log, [log]: true },
  // };

  const updates = {};
  updates[`/${subscriptionBaseUrl}/${subscriptionId}`] = subscription;

  firebase.database().ref().update(updates);
};

export const getSubscriptionById = async (subscriptionId) => {
  let fetchedSubscription = {};
  await firebase
    .database()
    .ref(`${subscriptionBaseUrl}/${subscriptionId}`)
    .once('value')
    .then((snap) => {
      const snapData = snap.val();
      const subscription = {
        ...snapData,
        id: snap.key,
      };
      fetchedSubscription = subscription;
    });

  return fetchedSubscription;
};

export const validatePayPalSubscription = async (payPalSubscriptionId) => {
  let response = {};

  await fetch(`${paypalBaseUrl}/v1/billing/subscriptions/${payPalSubscriptionId}`, {
    method: 'GET',
    headers: new Headers({
      Authorization: `Basic ${btoa(`${payPalConfig.clientId}:${payPalConfig.secret}`)}`,
    }),
  })
    .then((res) => {
      response = { ...response, statusCode: res.status };
      return res.json();
    })
    .catch((error) => console.error('Error:', error))
    .then((json) => {
      response = { ...response, ...json };
    });

  return response;
};

export const cancelPayPalSubscription = async (payPalSubscriptionId, reason) => {
  let response = {};
  const body = {
    reason,
  };

  await fetch(`${paypalBaseUrl}/v1/billing/subscriptions/${payPalSubscriptionId}/cancel`, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Basic ${btoa(`${payPalConfig.clientId}:${payPalConfig.secret}`)}`,
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(body),
  })
    .then((res) => {
      const result = res;
      console.log('res', result);
      response = { ...response, statusCode: result.status };
      return result?.json();
    })
    .catch((error) => console.error('Error:', error))
    .then((json) => {
      const jsonResult = json;
      console.log('json', jsonResult);
      response = {
        ...response,
        ...jsonResult,
      };
      console.log('response', response);
    });
  return response;
};

export const upgradeOrDowngradeSubscription = async (company, newPlanId) => {
  let response = {};
  const body = { plan_id: newPlanId };

  // console.log('company.payPalSubscriptionId', company.subscription.payPalSubscriptionId);

  await fetch(`${paypalBaseUrl}/v1/billing/subscriptions/${company.subscription.payPalSubscriptionId}/revise`, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Basic ${btoa(`${payPalConfig.clientId}:${payPalConfig.secret}`)}`,
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(body),
  })
    .then((res) => {
      const result = res;
      console.log('res', result);

      response = { ...response, statusCode: result.status };
      return result.json();
    })
    .catch((error) => console.error('Error:', error))
    .then((json) => {
      const jsonResult = json;
      console.log('json', jsonResult);
      response = {
        ...response,
        ...jsonResult,
      };
      console.log('response', response);
    });
  return response;
};

// const showPayPalLoginPageToreConsent = async (url) => {
//   let response = {};

//   await fetch(url, {
//     method: 'GET',
//     headers: new Headers({
//       Authorization: `Basic ${btoa(`${payPalConfig.clientId}:${payPalConfig.secret}`)}`,
//       'Content-Type': 'application/json',
//     }),
//   })
//     .then((res) => {
//       const result = res;
//       console.log('res', result);

//       response = { ...response, statusCode: result.status };
//       return result.json();
//     })
//     .catch((error) => console.error('Error:', error))
//     .then((json) => {
//       const jsonResult = json;
//       console.log('json', jsonResult);
//       response = {
//         ...response,
//         ...jsonResult,
//       };
//       console.log('response', response);
//     });
//   return response;
// };

export const changeCompanyPlan = async (subscription, company, newPlan) => {
  const result = await upgradeOrDowngradeSubscription(company, newPlan.planId);
  return result;
};
