import { createSlice } from '@reduxjs/toolkit';
import omit from 'lodash/omit';
// utils
// import axios from '../../utils/axios';
//
// import { dispatch } from '../store';
import {
  changeColumnOrder,
  changeColumns,
  createBoard,
  createCard,
  createQueueColumn,
  deleteCard,
  deleteQueueColumn,
  getCurrentBoard,
  getQueuesByBranch,
  updateQueueColumn,
} from '../../services/QueueService';
import { addPointsToUser } from '../../services/RatingsService';

// ----------------------------------------------------------------------

// function objFromArray(array, key = 'id') {
//   console.log('array', array);
//   if (Object.keys(array).length === 0) {
//     return [];
//   }
//   return array?.reduce((accumulator, current) => {
//     accumulator[current[key]] = current;
//     return accumulator;
//   }, {});
// }

export const initialState = {
  isLoading: false,
  error: null,
  usersList: [],
  clientsList: [],
  currentCard: {},
  queuesList: [],
  allConversations: {},
  currentBoard: {
    cards: {},
    columns: {
      cero: {
        cardIds: [],
        id: 'cero',
        name: 'Sin atender',
      },
      one: {
        cardIds: [],
        id: 'one',
        name: 'En conversación',
      },
      two: {
        cardIds: [],
        id: 'two',
        name: 'En pausa',
      },
      three: {
        cardIds: [],
        id: 'three',
        name: 'Finalizado',
      },
    },
    currentSession: {},
    columnOrder: ['cero', 'one', 'two', 'three'],
  },
};

const slice = createSlice({
  name: 'queue',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET BOARD
    setBoard(state, action) {
      const { currentBoard, currentQueue } = action.payload;
      state.currentBoard = currentBoard;
      state.currentSession = currentQueue;

      // state.isLoading = false;
      // const board = action.payload;
      // const cards = objFromArray(board?.cards);
      // const columns = objFromArray(board?.columns);
      // const columnOrder = board?.columnOrder;
      // state.currentBoard = {
      //   cards,
      //   columns,
      //   columnOrder,
      // };
    },

    // CREATE NEW COLUMN
    createColumnSuccess(state, action) {
      const newColumn = action.payload;
      state.isLoading = false;
      state.currentBoard.columns = {
        ...state.currentBoard.columns,
        [newColumn.id]: newColumn,
      };
      state.currentBoard.columnOrder.push(newColumn.id);
    },

    persistCard(state, action) {
      const { columns, card, finish } = action.payload;
      state.currentBoard.columns = columns;
      state.currentBoard.cards[card.id].columnId = finish.id;
    },

    persistColumn(state, action) {
      state.currentBoard.columnOrder = action.payload;
    },

    addTask(state, action) {
      const { card, columnId, currentBoard } = action.payload;

      // console.log('card', card);

      if (!currentBoard.cards) {
        state.currentBoard = {
          ...currentBoard,
          cards: {},
        };
      }
      if (!currentBoard.columns[columnId].cardIds) {
        state.currentBoard = {
          ...state.currentBoard,
          columns: {
            ...state.currentBoard.columns,
            [columnId]: {
              ...state.currentBoard.columns[columnId],
              cardIds: [],
            },
          },
        };
      }

      state.currentBoard.cards[card.id] = card;
      state.currentBoard.columns[columnId].cardIds.push(card.id);
    },

    deleteTask(state, action) {
      const { cardId, columnId } = action.payload;

      state.currentBoard.columns[columnId].cardIds = state.currentBoard.columns[columnId].cardIds.filter(
        (id) => id !== cardId
      );
      state.currentBoard.cards = omit(state.currentBoard.cards, [cardId]);
    },

    // UPDATE COLUMN
    updateColumnSuccess(state, action) {
      const column = action.payload;

      state.isLoading = false;
      state.currentBoard.columns[column.id] = column;
    },

    // DELETE COLUMN
    deleteColumnSuccess(state, action) {
      const { columnId } = action.payload;
      const deletedColumn = state.currentBoard.columns[columnId];

      state.isLoading = false;
      state.currentBoard.columns = omit(state.currentBoard.columns, [columnId]);
      state.currentBoard.cards = omit(state.currentBoard.cards || {}, [...(deletedColumn.cardIds || [])]);
      state.currentBoard.columnOrder = state.currentBoard.columnOrder.filter((c) => c !== columnId);
    },

    // clear board
    clear(state, action) {
      state.currentBoard = action.payload;
    },

    // SET CURRENT BOARD
    setCurrentBoard(state, action) {
      state.currentBoard = action.payload;
    },

    // SET CURRENT CARD
    setCurrentCard(state, action) {
      state.currentCard = action.payload;
    },

    // SET USERS LIST
    setUsersList(state, action) {
      state.usersList = action.payload;
    },

    // SET USERS LIST
    setClientsList(state, action) {
      state.clientsList = action.payload;
    },

    setQueues(state, action) {
      const { queuesList } = action.payload;
      state.queuesList = queuesList;
    },

    setAllConversations(state, action) {
      const { allConversations } = action.payload;
      state.allConversations = allConversations;
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;
export const {
  setBoard,
  createColumnSuccess,
  setCurrentBoard,
  setUsersList,
  setClientsList,
  setQueues,
  setAllConversations,
} = actions;

// ----------------------------------------------------------------------

// no se usa
export function getNewBoard() {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    // try {
    // const response = await axios.get('/api/kanban/board');
    // const board = { cards: {}, columns: {}, columnOrder: [] };
    dispatch(setBoard({ currentBoard: initialState.currentBoard }));
    // } catch (error) {
    //   dispatch(slice.actions.hasError(error));
    // }
  };
}

const validateIsCardInList = (currentBoard, cardId) => {
  const currentCards = Object.keys(currentBoard?.cards || {});
  console.log('currentCards', currentCards);
  console.log('cardId', cardId);
  return currentCards.includes(cardId);
};

const getConversationCards = (conversations, currentBoard) => {
  const cards = {};
  let { cero } = currentBoard?.columns || {};
  const { one, two, three, four } = currentBoard?.columns || {};

  console.log('cero xdd', cero);

  Object.values(conversations.byId).forEach((el) => {
    const newCard = {
      name: el.userName,
      id: el.id,
      completed: false,
      attachments: {},
      description: '',
      sessionId: el.sessionId,
      columnId: 'cero',
    };

    // if (!cero.cardIds) {
    //   cero.cardIds = [];
    // }

    const isCardInList = validateIsCardInList(currentBoard, el.id);
    console.log('isCardInList', isCardInList);
    if (!isCardInList) {
      cero = {
        ...cero,
        cardIds: [...(cero?.cardIds || []), newCard.id],
      };
    }
    cards[el.id] = newCard;
  });

  return { cards, cero, one, two, three, four };
};

const getNewBoardWithCards = (conversations, currentBoard) => {
  const { cards, cero, one, two, three, four } = getConversationCards(conversations, currentBoard);
  // console.log('cards', cards);
  // console.log('cero', cero);

  const newBoard = {
    ...currentBoard,
    cards,
    columns: { ...(currentBoard?.columns || {}), cero, one, two, three, four },
  };

  return newBoard;
};

export function fetchBoard({ branchId, boardId, allConversations, currentQueue }) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    // try {
    console.log('allConversations xdd', allConversations);
    let board = initialState.currentBoard;
    if (boardId) {
      console.log('fetchBoard 1');
      board = await getCurrentBoard(branchId, currentQueue.id);
      // const boardTemp = await getCurrentBoard(branchId, currentSession.id);
      // board = getNewBoardWithCards(allConversations, boardTemp);
    } else {
      console.log('fetchBoard 2');
      board = await getCurrentBoard(branchId, currentQueue.id);
      // const boardTemp = await getCurrentBoard(branchId, currentSession.id);
      // console.log('board', board);
      if (!board) {
        console.log('fetchBoard 3');
        // const newBoard = getNewBoardWithCards(allConversations, initialState.currentBoard);
        console.log('newBoard', board);
        console.log('currentQueue xd', currentQueue);
        board = await createBoard(branchId, board, currentQueue.id);
        // board = await createBoard(branchId, newBoard, currentSession.id);
      } else {
        // board = getNewBoardWithCards(allConversations, board);
        // board = getNewBoardWithCards(allConversations, boardTemp);
      }
    }
    console.log('board', board);
    dispatch(setBoard({ currentBoard: board, currentQueue }));
    // } catch (error) {
    //   dispatch(slice.actions.hasError(error));
    // }
  };
}

// ----------------------------------------------------------------------

export function createColumn({ branchId, currentBoard, newColumn }) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    // try {
    // const response = await axios.post('/api/kanban/columns/new', newColumn);

    const columnId = await createQueueColumn(branchId, currentBoard, newColumn);
    dispatch(createColumnSuccess({ ...newColumn, id: columnId }));
    // } catch (error) {
    //   dispatch(slice.actions.hasError(error));
    // }
  };
}

// ----------------------------------------------------------------------

export function updateColumn({ branchId, currentBoard, column, newName, handleShowMessage }) {
  return async (dispatch) => {
    await updateQueueColumn(branchId, currentBoard, column, newName);
    dispatch(slice.actions.updateColumnSuccess({ ...column, name: newName }));

    handleShowMessage();
    // dispatch(slice.actions.startLoading());
    // try {
    //   const response = await axios.post('/api/project/columns/update', {
    //     columnId,
    //     updateColumn,
    //   });
    //   dispatch(slice.actions.updateColumnSuccess(response.data.column));
    // } catch (error) {
    //   dispatch(slice.actions.hasError(error));
    // }
  };
}

// ----------------------------------------------------------------------

export function deleteColumn({ branchId, companyId, sessionId, currentBoard, columnId, handleShowMessage }) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());

    await deleteQueueColumn(branchId, companyId, sessionId, currentBoard, columnId);
    dispatch(slice.actions.deleteColumnSuccess({ columnId }));

    handleShowMessage();
    // try {
    //   await axios.post('/api/project/columns/delete', { columnId });
    //   dispatch(slice.actions.deleteColumnSuccess({ columnId }));
    // } catch (error) {
    //   dispatch(slice.actions.hasError(error));
    // }
  };
}

export function clearBoard() {
  return async (dispatch) => {
    dispatch(slice.actions.clear(initialState.currentBoard));
  };
}

// ----------------------------------------------------------------------

export function persistColumn({ newColumnOrder, branchId, currentBoard }) {
  return async (dispatch) => {
    await changeColumnOrder(branchId, currentBoard, newColumnOrder);
    dispatch(slice.actions.persistColumn(newColumnOrder));
  };
}

// ----------------------------------------------------------------------

export function persistCard({ columns, branchId, currentBoard, start, finish, draggableId }) {
  return async (dispatch) => {
    const card = currentBoard.cards[draggableId];
    await changeColumns(branchId, currentBoard, columns, start, finish, card);
    dispatch(slice.actions.persistCard({ columns, card, finish }));
  };
}

// ----------------------------------------------------------------------

export function addTask({ card, columnId, branchId, currentBoard, user }) {
  return async (dispatch) => {
    const cardId = await createCard(branchId, currentBoard, card, columnId);
    // console.log('cardId', cardId);
    const newCard = {
      ...card,
      id: cardId,
      columnId,
    };
    dispatch(slice.actions.addTask({ card: newCard, columnId, currentBoard }));

    await addPointsToUser('CRM', 'create', user, card.name);
  };
}

// ----------------------------------------------------------------------

export function deleteTask({ cardId, columnId, branchId, currentBoard, user, card }) {
  return async (dispatch) => {
    await deleteCard(branchId, currentBoard, cardId, columnId);
    dispatch(slice.actions.deleteTask({ cardId, columnId }));

    await addPointsToUser('CRM', 'delete', user, card.name);
  };
}

// export function addElementInListCard({ name, element, currentBoard, card }) {
//   console.log('currentBoard', currentBoard);
//   const { cards } = currentBoard;
//   const newBoard = {
//     ...currentBoard,
//     cards: {
//       ...cards,
//       [card.id]: {
//         ...card,
//         [name]: [...card[name], element],
//       },
//     },
//   };
//   dispatch(setCurrentBoard(newBoard));
// }

// export function setElementInCard({ name, element, currentBoard, card }) {
//   const { cards } = currentBoard;
//   const newBoard = {
//     ...currentBoard,
//     cards: {
//       ...cards,
//       [card.id]: {
//         ...card,
//         [name]: [...card[name], element],
//       },
//     },
//   };
//   dispatch(setCurrentBoard(newBoard));
// }

export function fetchQueues({ branchId, setCurrentQueue }) {
  return async (dispatch) => {
    const queuesObject = await getQueuesByBranch(branchId);
    const queuesList = Object.values(queuesObject);
    // console.log('queues xd', queuesList);

    dispatch(setQueues({ queuesList }));
    if (setCurrentQueue && queuesList.length > 0) {
      setCurrentQueue(queuesList[0]);
    }
  };
}

export function setQueuesList({ branchId, queuesList }) {
  return async (dispatch) => {
    dispatch(setQueues({ queuesList }));
  };
}

export function setAllConversationsByCompany({ branchId, allConversations }) {
  return async (dispatch) => {
    dispatch(setAllConversations({ allConversations }));
  };
}

const getQueueStructured = (queue, columnId, cardIds) => {
  // queue.columns[columnId].cardIds = cardIds;
  const queue2 = {
    ...queue,
    columns: {
      ...queue.columns,
      [columnId]: {
        ...queue.columns[columnId],
        cardIds,
      },
    },
  };

  return queue2;
};

export function moveChatInAnotherQueue({ queuesList, currentConversation, queue, cardId }) {
  return async (dispatch) => {
    console.log('queuesList 0 xd', queuesList);

    if (currentConversation.currentQueueId === queue.id) {
      return;
    }

    let oldQueue = {};
    // desvinculateQueueFromConversation
    if (currentConversation.currentQueueId) {
      oldQueue = { ...(queuesList.find((el) => el.id === currentConversation.currentQueueId) || {}) };
      console.log('currentConversation.currentQueueId', currentConversation.currentQueueId);
      console.log('queuesList', queuesList);
      console.log('oldQueue 0', oldQueue);
      console.log('cardId', cardId);
      console.log('currentConversation.id', currentConversation.id);
      const oldColumnId = oldQueue.columnOrder[0];
      const oldCardIds = (oldQueue.columns[oldColumnId].cardIds || []).filter((el) => el !== cardId);
      console.log('oldCardIds', oldCardIds);
      // oldQueue.columns[oldColumnId].cardIds = oldCardIds;
      oldQueue = getQueueStructured(oldQueue, oldColumnId, oldCardIds);
      console.log('oldQueue 1', oldQueue);
    }

    // asignConversationQueue
    const newColumnId = queue.columnOrder[0];
    const newCard = {
      completed: false,
      description: '',
      id: currentConversation.id,
      name: currentConversation.userName,
      sessionId: currentConversation.sessionId,
      columnId: 'cero',
    };

    const newQueueTemp = { ...queue, cards: { ...queue.cards, [newCard.id]: newCard } };
    console.log('newQueueTemp', newQueueTemp);

    const newCardIds = [...(newQueueTemp.cardIds || []), newCard.id];
    // newQueue.columns[newColumnId].cardIds = newCardIds;
    const newQueue = getQueueStructured(newQueueTemp, newColumnId, newCardIds);

    const newQueuesList = queuesList.map((el) => {
      if (el.id === newQueue.id) {
        return newQueue;
      }
      if (el.id === oldQueue.id) {
        return oldQueue;
      }
      return el;
    });

    console.log('queuesList 1 xd', queuesList);
    console.log('newQueuesList', newQueuesList);
    console.log('oldQueue', oldQueue);

    // se actualiza el tablero con la cola sin el card
    dispatch(setBoard({ currentBoard: oldQueue, currentQueue: oldQueue }));

    dispatch(setQueues({ queuesList: newQueuesList }));
  };
}
