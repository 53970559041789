import { createSlice } from '@reduxjs/toolkit';
import { getClientsByCompany } from '../../_apis_/clients';

const initialState = {
  clientListState: [],
  newClientAdded: '',
};

const currentBranch = localStorage.getItem('branchSelected');
if (currentBranch) {
  initialState.currentBranch = currentBranch;
}

const slice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    updateClientListState(state, action) {
      state.clientListState = action.payload;
    },
    addClientState(state, action) {
      console.log('redux ', action.payload);

      state.clientListState = [...state.clientListState, action.payload];
      state.newClientAdded = action.payload;
    },
    resetAddClientState(state) {
      state.newClientAdded = '';
    },
  },
});

export default slice.reducer;

export const { updateClientListState, addClientState, resetAddClientState } = slice.actions;

export function getClients(currentCompany) {
  return async (dispatch) => {
    try {
      const clients = await getClientsByCompany(currentCompany);
      const activeClients = clients.filter((client) => client.active);
      dispatch(slice.actions.updateClientListState(activeClients));
    } catch (err) {
      console.error(err);
    }
  };
}
