import firebase from 'firebase/app';
import { initialState, setCurrentBoard } from '../redux/slices/project';
import { dispatch } from '../redux/store';
import { createLog } from '../redux/slices/log';

const projectBaseUrl = 'project_branch';

const getCardBaseUrl = (branchId, cardId, familyId) => `${projectBaseUrl}/${branchId}/${familyId}/cards/${cardId}`;

const getColumnBaseUrl = (branchId, columnId, familyId) =>
  `${projectBaseUrl}/${branchId}/${familyId}/columns/${columnId}`;

export async function getCurrentBoard(branchId, familyId) {
  const rootRef = await firebase
    .database()
    .ref(`/${projectBaseUrl}/${branchId}/${familyId}`)
    .once('value')
    .then((snap) => snap.val());
  return rootRef;
}

export const createBoard = async (branchId, board, familyId) => {
  const boardRef = firebase.database().ref(`/${projectBaseUrl}/${branchId}/${familyId}`);
  // const updates = {};
  // updates[`/${projectBaseUrl}/${branchId}/${boardId}`] = { id: boardId, ...board };
  // await firebase.database().ref().update(updates);
  // console.log('create board', boardId);
  const newBoard = {
    ...board,
    id: branchId,
    familyId,
  };
  await boardRef.set(newBoard);
  return newBoard;
};

// export const updateBoard = async (branchId, board) => {
//   const updates = {};
//   updates[`/${projectBaseUrl}/${branchId}/${board.id}`] = board;
//   await firebase.database().ref().update(updates);
//   console.log('update board', board.id);
//   return board.id;
// };

export const createProjectCard = async (branchId, currentBoard, card, columnId) => {
  // state.currentBoard.cards[card.id] = card;
  // const boardId = currentBoard.id;

  // genera numero unico
  const lastInvoiceNumber = (await projectCount(branchId)) || 0;
  const consecutive = lastInvoiceNumber + 1;
  const consecutiveNumber = `PR-${consecutive}`;

  const cardId = firebase.database().ref(`/${projectBaseUrl}/${branchId}/${currentBoard.familyId}`).push().key;
  const updates = {};
  const cardBaseUrl = getCardBaseUrl(branchId, cardId, currentBoard.familyId);
  updates[`/${cardBaseUrl}`] = { ...card, consecutiveNumber, id: cardId, columnId };

  // state.currentBoard.columns[columnId].cardIds.push(card.id);
  const cardIds = [...(currentBoard.columns[columnId]?.cardIds || []), cardId];

  // const cardIds = currentBoard.columns[columnId]?.cardIds || [];
  // console.log('cardIds',cardIds)
  // cardIds.push(cardId);

  const columnBaseUrl = getColumnBaseUrl(branchId, columnId, currentBoard.familyId);
  updates[`/${columnBaseUrl}/cardIds`] = cardIds;

  await firebase.database().ref().update(updates);

  await createLog('project', 'create', `${cardId}`, [], card);
  await updateProjectCount(branchId);
  return { cardId, consecutiveNumber };
};

export const updateProjectCard = async (branchId, card, oldCard = [], familyId) => {
  console.log('branchId', branchId);
  console.log('card,', card);
  console.log('oldCard ', oldCard);
  console.log('familyId', familyId);

  // pendiente
  const log = await createLog('project', 'update', `${card.id}`, oldCard, card);

  const currentBoard = (await getCurrentBoard(branchId, familyId)) || { ...initialState.currentBoard, familyId };
  // console.log('currentBoard', currentBoard);

  // const cardBaseUrl = getCardBaseUrl(branchId, card.id);
  // updates[`/${cardBaseUrl}`] = card;
  // `${projectBaseUrl}/${branchId}/cards/${cardId}`

  const newBoard = {
    ...currentBoard,
    cardLogs: { ...currentBoard?.cardLogs, [log]: true },
    cards: {
      ...currentBoard.cards,
      [card.id]: card,
    },
  };

  console.log('newBoard', newBoard);

  const updates = {};
  updates[`/${projectBaseUrl}/${branchId}/${familyId}`] = newBoard;

  await firebase.database().ref().update(updates);
  dispatch(setCurrentBoard(newBoard));
};

export const deleteCard = async (branchId, currentBoard, cardId, columnId) => {
  // const boardId = currentBoard.id;
  // state.currentBoard.columns[columnId].cardIds = state.currentBoard.columns[columnId].cardIds.filter(
  //   (id) => id !== cardId
  // );
  const updates = {};
  const columnBaseUrl = getColumnBaseUrl(branchId, columnId, currentBoard.familyId);
  const { cardIds } = currentBoard.columns[columnId];
  const newList = cardIds.filter((id) => id !== cardId);

  updates[`/${columnBaseUrl}/cardIds`] = newList;
  await firebase.database().ref().update(updates);

  // state.currentBoard.cards = omit(state.currentBoard.cards, [cardId]);
  const cardBaseUrl = getCardBaseUrl(branchId, cardId, currentBoard.familyId);
  const rootRef = firebase.database().ref(`/${cardBaseUrl}`);
  await rootRef.remove();
};

// newColumnOrder
export const changeColumnOrder = async (branchId, currentBoard, newColumnOrder) => {
  const boardUrl = `${projectBaseUrl}/${branchId}/${currentBoard.familyId}/columnOrder`;
  const updates = {};
  updates[`/${boardUrl}`] = newColumnOrder;
  await firebase.database().ref().update(updates);
};

// persistCard
export const changeColumns = async (branchId, columns, start, finish, card, familyId) => {
  await createLog('project', 'moved', `${card.id}`, { progress: start.name }, { progress: finish.name });

  const boardUrl = `${projectBaseUrl}/${branchId}/${familyId}/columns`; // pendiente
  const updates = {};
  updates[`/${boardUrl}`] = columns;
  await firebase.database().ref().update(updates);
};

export const getProjectCards = async (branchId, familyId) => {
  // pendiente
  const data = await firebase
    .database()
    .ref(`/${projectBaseUrl}/${branchId}/${familyId}/cards`)
    .once('value')
    .then((snap) => snap.val());
  return data;
};

export const getCardById = async (branchId, cardId, familyId) => {
  const data = await firebase
    .database()
    .ref(`/${projectBaseUrl}/${branchId}/${familyId}/cards/${cardId}`)
    .once('value')
    .then((snap) => snap.val());
  return data;
};

// export const addDocumentToCard = async (branchId, cardId, doc) => {
//   const cardBaseUrl = getCardBaseUrl(branchId, cardId);
//   const currentCard = await getCardById(branchId, cardId);

//   const newCard = {
//     ...currentCard,
//     documents: [...(currentCard?.documents || []), doc],
//   };

//   const updates = {};
//   updates[`/${cardBaseUrl}`] = newCard;
//   await firebase.database().ref().update(updates);
// };

const setCard = (name, element, currentBoard, card) => {
  const { cards } = currentBoard;
  const newBoard = {
    ...currentBoard,
    cards: {
      ...cards,
      [card.id]: {
        ...card,
        [name]: { ...(card[name] || {}), [element.id]: element },
      },
    },
  };
  console.log('newBoard', newBoard);
  dispatch(setCurrentBoard(newBoard));
};

export const addFileOnCard = async (branchId, cardId, file, currentBoard, card) => {
  const cardBaseUrl = getCardBaseUrl(branchId, cardId, currentBoard.familyId);
  const updates = {};
  updates[`/${cardBaseUrl}/files_path/${file.id}`] = file;
  await firebase.database().ref().update(updates);

  setCard('files_path', file, currentBoard, card);
};

export const getUrlFileOfAnAttachment = async (branchId, cardId, idEncoded, familyId) => {
  const cardBaseUrl = getCardBaseUrl(branchId, cardId, familyId);

  const rootRef = await firebase
    .database()
    .ref(`/${cardBaseUrl}/files_path/${idEncoded}`)
    .once('value')
    .then((snap) => snap.val());
  return rootRef;
};

export const updateFamilyList = async (familyList, companyId) => {
  // const newBoard = {
  //   ...currentBoard,
  //   familyList
  // };

  const updates = {};
  updates[`/companies/${companyId}/familyList`] = familyList;
  await firebase.database().ref().update(updates);

  // console.log('newBoard', newBoard);
};

export const projectCount = async (branchId) => {
  const projectCount = await firebase
    .database()
    .ref(`branches/${branchId}/projectCount`)
    .once('value')
    .then((snap) => snap.val());
  return projectCount;
};

export const updateProjectCount = async (branchId) => {
  const newProjectCount = (await projectCount(branchId)) + 1;
  // console.log(newInvoiceCount);
  await firebase.database().ref(`branches/${branchId}`).update({ projectCount: newProjectCount });
};

// Family

export const createFamilyColumn = async (branchId, currentBoard, column) => {
  const familyBaseUrl = `/${projectBaseUrl}/${branchId}/${currentBoard.familyId}`;
  // columnId
  const newColumn = await firebase.database().ref(`${familyBaseUrl}/columns`).push(column);

  // add in column order
  const updates = {};

  const newColumnOrder = [...(currentBoard.columnOrder || []), newColumn.key];
  updates[`${familyBaseUrl}/columnOrder`] = newColumnOrder;
  updates[`${familyBaseUrl}/columns/${newColumn.key}/id`] = newColumn.key;

  firebase.database().ref().update(updates);

  return newColumn.key;
};

export const updateFamilyColumn = async (branchId, currentBoard, column, newName) => {
  const familyBaseUrl = `/${projectBaseUrl}/${branchId}/${currentBoard.familyId}`;
  const updates = {};
  updates[`${familyBaseUrl}/columns/${column.id}/name`] = newName;
  firebase.database().ref().update(updates);
};

export const deleteFamilyColumn = async (branchId, currentBoard, columnId) => {
  const cardsInColumnToDelete = currentBoard.columns[columnId] || [];
  const familyBaseUrl = `/${projectBaseUrl}/${branchId}/${currentBoard.familyId}`;
  firebase.database().ref(`${familyBaseUrl}/columns/${columnId}`).remove();

  // delete column at the column order
  const newColumnOrder = currentBoard.columnOrder.filter((el) => el !== columnId);
  const updates = {};
  updates[`${familyBaseUrl}/columnOrder`] = newColumnOrder;

  // filter cards
  const activeCards = currentBoard.cards.filter((el) => !cardsInColumnToDelete.includes(el.id));
  updates[`${familyBaseUrl}/cards`] = activeCards;
  firebase.database().ref().update(updates);
};

export const deleteAllFamily = async (branchId, currentBoard) => {
  const familyBaseUrl = `/${projectBaseUrl}/${branchId}/${currentBoard.familyId}`;
  console.log('familyBaseUrl', familyBaseUrl);
  firebase.database().ref(`${familyBaseUrl}`).remove();
};

// conversation
export async function getCurrentConversation(branchId, cardId, familyId) {
  const cardBaseUrl = getCardBaseUrl(branchId, cardId, familyId);
  const rootRef = await firebase
    .database()
    .ref(`${cardBaseUrl}/conversation`)
    .once('value')
    .then((snap) => snap.val());
  return rootRef;
}

export const createNoteConversation = async (branchId, conversation, cardId, familyId) => {
  // const boardRef = firebase.database().ref(`/${projectBaseUrl}/${branchId}/${familyId}/cards/${cardId}`);
  const cardBaseUrl = getCardBaseUrl(branchId, cardId, familyId);
  const boardRef = firebase.database().ref(`${cardBaseUrl}/conversation`);

  // const updates = {};
  // updates[`/${projectBaseUrl}/${branchId}/${boardId}`] = { id: boardId, ...board };
  // await firebase.database().ref().update(updates);
  // console.log('create board', boardId);
  const newBoard = {
    ...conversation,
    id: cardId,
    branchId,
  };
  await boardRef.set(newBoard);
  return newBoard;
};

export const sendNote = async (branchId, cardId, familyId, newMessages) => {
  const cardBaseUrl = getCardBaseUrl(branchId, cardId, familyId);
  const boardRef = firebase.database().ref(`${cardBaseUrl}/conversation/messages`);
  await boardRef.set(newMessages);

  // bug del listening
  if (newMessages.length === 1) {
    await boardRef.set([...newMessages, newMessages[0]]);
    await boardRef.set(newMessages);
  }
};

export const addParticipantIntoCard = async (branchId, cardId, familyId, participants) => {
  const cardBaseUrl = getCardBaseUrl(branchId, cardId, familyId);
  const boardRef = firebase.database().ref(`${cardBaseUrl}/conversation/participants`);
  await boardRef.set(participants);

  // bug del listening
  if (participants.length === 1) {
    await boardRef.set([...participants, participants[0]]);
    await boardRef.set(participants);
  }
};

export const listenForChangesInNoteConversation = async (branchId, cardId, familyId, callback) => {
  const cardBaseUrl = getCardBaseUrl(branchId, cardId, familyId);

  const myTable = firebase.database().ref(`/${cardBaseUrl}/conversation`);
  myTable.on('child_changed', async (snapshot) => {
    const data = snapshot.val();
    console.log('data Realtime child_changed', data);
    callback(data);
  });

  return myTable;
};

export const clearListeningNotesConversation = (branchId, cardId, familyId) => {
  const cardBaseUrl = getCardBaseUrl(branchId, cardId, familyId);
  const myTable = firebase.database().ref(`/${cardBaseUrl}/conversation`);
  myTable.off('child_changed');
};
