import firebase from 'firebase/app';
import { createLog } from '../redux/slices/log';
import { getCompanyById } from '../_apis_/companies';

export async function getAvailableRoles(companyId, showDefaultRoles = false) {
  const rootRef = await firebase
    .database()
    .ref(`/company_roles/${companyId}`)
    .once('value')
    .then((snap) => snap.val() || {});

  let defaultRoles = {};
  if (showDefaultRoles) {
    defaultRoles = await firebase
      .database()
      .ref(`/company_roles/QOLOLO_ADMIN`)
      .once('value')
      .then((snap) => snap.val() || {});
  }

  return { ...defaultRoles, ...rootRef };
}

export const getRoleByUid = async (companyId, uid) => {
  const role = await firebase
    .database()
    .ref(`/company_roles/${companyId}/${uid}`)
    .once('value')
    .then((snap) => snap.val());
  return role;
};

export const createRole = async (companyId, values) => {
  const newUserRef = await firebase.database().ref(`/company_roles/${companyId}`);
  const role = await newUserRef.push(values);

  const log = await createLog('role', 'create', role.key, [], values);
  values = {
    ...values,
    id: role.key,
    log: { ...values.log, [log]: true },
  };
  role.update(values);

  return role.key;
};

// export const editRole = async (uid, values) => {
//   const newUserRef = await firebase.database().ref(`/roles/${uid}`);
//   await newUserRef.update(values);
// };

// export const addRolesInCompany = async (companyId, role) => {
//   const rootRef = firebase.database().ref(`/company_roles/${companyId}/${role.uid}`);
//   const newRole = { name: `${role.name}` };
//   await rootRef.set(newRole);
// };

export const editRolesInCompany = async (companyId, uid, role) => {
  const rootRef = firebase.database().ref(`/company_roles/${companyId}/${uid}`);
  await rootRef.update(role);
};

export const getPermissionsOfRoleByUid = async (companyId, uid, showDefaultRoles = true) => {
  const role = await firebase
    .database()
    .ref(`/company_roles/${companyId}/${uid}/permissions`)
    .once('value')
    .then((snap) => snap.val());

  let defaultRoles = {};
  if (showDefaultRoles) {
    defaultRoles = await firebase
      .database()
      .ref(`/company_roles/QOLOLO_ADMIN/${uid}/permissions`)
      .once('value')
      .then((snap) => snap.val() || {});
  }
  return { ...defaultRoles, ...role };
};

export const deleteRole = async (roleId, companyId) => {
  // const quoteRemoved = await getRoleByCompany(roleId, companyId);
  // quoteRemoved.status = 'removed';
  // await banishRole(roleRemoved, companyId);

  const rootRef = firebase.database().ref('/');

  const roles = rootRef.child(`company_roles/${companyId}`);
  const currentRoleRef = roles.child(roleId);
  currentRoleRef.remove();

  // const companies = rootRef.child('companies');
  // const parentCompanyRef = companies.child(companyId);
  // const parentCompany = await getCompanyById(companyId);
  // // const rolesArray = parentCompany?.roles;
  // // const companyRoles = rolesArray.filter((item) => item !== roleId);

  // delete parentCompany.roles[roleId];

  // const updatedParentCompany = {
  //   ...parentCompany,
  //   roles: parentCompany.roles,
  // };

  // const update = await parentCompanyRef.update(updatedParentCompany);
  // return update;
};
